import React, { Component } from "react";
import HouseImageListItem from "./houseImageListItem";
import { Row, Col } from "reactstrap";

class HouseImagesList extends Component {
  render() {
    return (
      <Row>
        {this.props.images.map((i) => (
          <Col sm="4" key={i.id}>
            <HouseImageListItem
              image={i}
              onDelete={this.props.onDelete}
              onSelect={this.props.onSelect}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default HouseImagesList;
