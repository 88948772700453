import React, {Component} from "react";

import EntityTable from "./../entityTable";

class UsersTable extends Component {
    state = {data: {}, url: "users"};

    getColumns = () => {
        return [
            {id: "firstName", Header: "Name", accessor: d => d.firstName + " " + d.lastName},
            {
                id: "cultureName",
                Header: "Lang",
                accessor: d => d.cultureName,
                width: 50,
                filterable: false
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: props => <span className="number">{props.value}</span>
            },
            {
                id: "phoneNumber",
                Header: "Phone number",
                accessor: d => d.phoneNumber,
                width: 120,
                filterable: true
            },
            {
                id: "minPrice",
                Header: "Min price",
                accessor: d => d.minPrice,
                width: 80,
                filterable: false,
                sortable: false
            },
            {
                id: "maxPrice",
                Header: "Max price",
                accessor: d => d.maxPrice,
                width: 80,
                filterable: false,
                sortable: false
            },
            {
                id: "sizeRange",
                Header: "Size",
                accessor: d => d.sizeRange,
                width: 50,
                filterable: false,
                sortable: false
            },
            {
                id: "roomRange",
                Header: "Beds",
                accessor: d => d.roomRange,
                width: 65,
                filterable: false,
                sortable: false
            },
            {
                id: "furnishing",
                Header: "Furnishing",
                accessor: d => d.furnishing,
                width: 100,
                filterable: false,
                sortable: false
            },
            {id: "city", Header: "City", accessor: d => d.city, width: 100},
            {
                id: "isActive",
                Header: "Active",
                accessor: d => d.isActive.toString(),
                width: 80,
                sortable: false,
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: "100%"}}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                )
            },
            {
                id: "excludeSharingNotAllowed",
                Header: "Exc.Sharing",
                accessor: d => d.excludeSharingNotAllowed.toString(),
                width: 100,
                sortable: false,
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: "100%"}}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                )
            },
            {
                id: "excludeStudentsNotAllowed",
                Header: "Exc.Students",
                accessor: d => d.excludeStudentsNotAllowed.toString(),
                width: 100,
                sortable: false,
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: "100%"}}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                )
            },
            {
                id: "excludeSeniorHousing",
                Header: "Exc. 50+",
                accessor: d => d.excludeSeniorHousing.toString(),
                width: 100,
                sortable: false,
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: "100%"}}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                )
            },
            {
                id: "createDate",
                Header: "Create date",
                accessor: d => d.createDate,
                filterable: false
            },
            {
                id: "modifiedDate",
                Header: "Modified date",
                accessor: "modifiedDate",
                filterable: false
            }
        ];
    };

    render() {
        return (
            <EntityTable
                url="users"
                columns={this.getColumns()}
                linkToEditIndex="0"
            />
        );
    }
}

export default UsersTable;
