import React, {useEffect, useState} from "react";
import httpService from "../../services/httpService";
import {Button} from "reactstrap";

export default function EditSource(props) {

    const [source, setSource] = useState({});
    const [domain, setDomain] = useState("");
    const [baseUrl, setBaseUrl] = useState("");
    const [threshold, setThreshold] = useState(0);
    const [isBaseUrlValid, setIsBaseUrlValid] = useState(false);

    useEffect(() => {
        httpService.get(`sources/${props.match.params.id}`).then(({data}) => {
            setDomain(data.domain);
            setBaseUrl(data.baseUrl);
            setIsBaseUrlValid(data.isBaseUrlValid);
            setThreshold(data.threshold);
            setSource(data);
        });
    }, []);

    const deleteSource = () => {
        if(window.confirm("Are you sure you want to delete this source?")) {
            httpService.delete(`sources/${props.match.params.id}`)
                .then(() => {
                    // Redirect or update state here after successful deletion
                })
                .catch(err => {
                    // Handle error here
                });
        }
    }

    return <>
    <form method="POST" onSubmit={(e) => {
        e.preventDefault();
        httpService.put(`sources/${props.match.params.id}`, {
            ...source,
            domain,
            baseUrl,
            isBaseUrlValid,
            threshold
        }).then(response => setSource(response.data));
    }}>
        <label htmlFor="domain">
            Domain
            <input id="domain" type="text" value={domain}
                   onChange={(e) => setDomain(e.target.value)}/>
        </label>
        <label htmlFor="baseUrl">
            Base url
            <input id="baseUrl" type="text" value={baseUrl}
                   onChange={(e) => setBaseUrl(e.target.value)}/>
        </label>
        <label htmlFor="threshold">
            Threshold
            <input id="threshold" type="text" value={threshold}
                   onChange={(e) => setThreshold(parseInt(e.target.value))}/>
        </label>
        <label htmlFor="isBaseUrlValid">
            Is Valid
            <input id="isBaseUrlValid" type="checkbox" checked={isBaseUrlValid}
                   onChange={(e) => setIsBaseUrlValid(e.target.checked)}/>
        </label>
        <Button type="submit">Submit</Button>
    </form>
    <Button type="button" onClick={deleteSource}>Delete Source</Button>
    </>
}
