function extractDomainFromURL(url) {
    var domain;

    // Find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
        domain = url.split("/")[2];
    } else {
        domain = url.split("/")[0];
    }

    // Find & remove port number
    domain = domain.split(":")[0];

    // Find & remove query string
    domain = domain.split("?")[0];

    return domain;
}

export async function testDomain(url, spiderName) {
    let options = {
        method: 'POST',
        body: `{"request":{"url":"${url}"}, "spider_name":"${spiderName}"}`,
    };
    return await requestCrawl(options);
}

export async function testUrl(url, spiderName) {
    let options = {
        method: 'POST',
        body: `{"request":{"url":"${url}", "callback": "parse_house", "dont_filter": "True", "meta": { "img_url": "https://thisisatest.com"} },"spider_name":"${spiderName}"}`,
    };
    if (spiderName === 'ogonline' || spiderName === 'realworks' || spiderName === 'goesenroos') {
        const domain = extractDomainFromURL(url);
        options.body = `{"request":{ "dont_filter": "True", "meta": { "img_url": "https://thisisatest.com"} }, "start_requests": "True", "crawl_args": {"domain": "${domain}"}, "spider_name":"${spiderName}"}`;
        const result = await requestCrawl(options);
        result.items = result.items.filter(item => item.Url[0] === url);
        return result;
    }

    return await requestCrawl(options);
}

async function requestCrawl(options) {
    const response = await fetch('https://rs-urltest.azurewebsites.net/crawl.json', options);
    return await response.json();
}
