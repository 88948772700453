import React from "react";
import {FormGroup, Input, Label} from "reactstrap";

export const NotificationMethodsInput = ({
                                             notificationMethods,
                                             userNotificationMethods,
                                             errors,
                                             onNotificationMethodsChange,
                                             disabled = false
                                         }) => {
    return (
        <>
            <h5>Notification Methods</h5>
            {notificationMethods.map(method => (
                <FormGroup key={method.item1} check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="userNotificationMethods"
                            id={method.id}
                            label={method.name}
                            value={method.id}
                            checked={
                                userNotificationMethods &&
                                userNotificationMethods.filter(id => id === method.id).length > 0
                            }
                            onChange={onNotificationMethodsChange}
                            disabled={disabled}
                        />{" "}
                        {method.name}
                    </Label>
                </FormGroup>
            ))}
            {errors["userNotificationMethods"] && (
                <div className="alert alert-danger">{errors["userNotificationMethods"]}</div>
            )}
        </>
    );
};