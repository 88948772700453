import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class BaseEditor extends Component {
  render() {
    return (
      <ReactQuill
        theme="snow"
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}
export default BaseEditor;
