import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = "https://" + window.location.host + "/api/";

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("logging the error", error);
    toast.error("An unexpected error has ocurred");
  }
  const authorizationError =
      error.response &&
      error.response.status === 403;
  if (authorizationError) {
    console.log("authorization error", error);
    toast.error("User is not authorized");
  }
  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

export default {
  get: axios,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
