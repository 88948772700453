import React from 'react'
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { AdminPermission } from './adminPermission'

class NavBar extends React.Component {
  state = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render () {
    const { user } = this.props
    return (
      <header className="position-sticky sticky-top">
        <Navbar color="dark" dark expand="md">
          <Container fluid>
            <NavbarBrand href="/">RentSlam Cms (v 1.8)</NavbarBrand>
            <NavbarToggler onClick={this.toggle} className="mr-2"/>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/users">
                    Users
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/parsedhouses">
                    Parsed houses
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light"
                           to="/testimonials">
                    Testimonials
                  </NavLink>
                </NavItem>
                <AdminPermission user={user}>
                  <NavItem>
                    <NavLink tag={Link} className="text-light" to="/payments">
                      Payments
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-light" to="/parsers">
                      Parsers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-light" to="/sources">
                      Sources
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Search criteria
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <Link className="dropdown-item"
                              to="/furnishingcriteria">
                          Furnishing
                        </Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link className="dropdown-item" to="/cities">
                          Cities
                        </Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link className="dropdown-item" to="/postcodes">
                          Postcodes
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink tag={Link} className="text-light"
                             to="/reportedproblems">
                      Reported Problems
                    </NavLink>
                  </NavItem>
                </AdminPermission>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/feedback">
                    Feedback
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/houses">
                    Houses
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light"
                           to="/statsperneighbourhood">
                    Stats
                  </NavLink>
                </NavItem>
                {!user && (
                  <NavItem>
                    <NavLink tag={Link} className="text-light" to="/login">
                      Login
                    </NavLink>
                  </NavItem>
                )}
                {user && (
                  <NavItem>
                    <NavLink tag={Link} className="text-light" to="/logout">
                      logout {user.name}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  }
}

export default NavBar
