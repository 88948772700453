import React from "react";
import { Input, Label } from "reactstrap";

const BaseSelectInput = ({ name, label, options, error, ...rest }) => {
  return (
    <React.Fragment>
      {" "}
      <Label for={name}>{label}</Label>
      <Input type="select" name={name} id={name} {...rest}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </Input>
      {error && <div className="alert alert-danger">{error}</div>}
    </React.Fragment>
  );
};

export default BaseSelectInput;
