import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import http from '../../services/httpService'
import { Link } from 'react-router-dom'

class ParsedHousesTable extends Component {
  state = { data: [], pages: null, loading: true, }
  delayTimer
  columns = [
    {
      id: 'edit', Header: '#', accessor: (d) => '#', filterable: false, sortable: false,
      width: 20,
      Cell: (props) => (
        <Link to={`/parsedhouses/${this.state.data[props.index].id}`}>
          {props.value}
        </Link>
      ),
    },
    {
      id: 'address', Header: 'Address', accessor: (d) => d.address, width: 230,
      Cell: (props) => (
        <a target="_blank" rel="noreferrer" href={this.state.data[props.index].url}>
          {props.value}
        </a>
      ),
    },
    { id: 'zipCode', Header: 'Postcode', accessor: (d) => d.zipCode, width: 80, },
    { id: 'city', Header: 'City', accessor: (d) => d.city, width: 120, },
    { id: 'price', Header: 'Price', accessor: (d) => d.price, sortable: false, width: 60, },
    { id: 'size', Header: 'Size', accessor: (d) => d.size, sortable: false, width: 40, },
    { id: 'bedrooms', Header: 'Beds', accessor: (d) => d.bedrooms, sortable: false, width: 50 },
    { id: 'furnishing', Header: 'Furnishing', accessor: (d) => d.furnishing, sortable: false, },
    { id: 'type', Header: 'Type', accessor: (d) => d.type, sortable: false, },
    { id: 'source', Header: 'Source', accessor: (d) => d.source, filterable: true, width: 230, },
    {
      id: 'duplicateOfId',
      Header: 'Duplicate of',
      accessor: (d) => d.duplicateOfId,
      filterable: true,
      width: 230,
      Cell: (props) => (
        <Link target="blank" to={`/parsedhouses/${props.value}`}>
          {this.state.data[props.index].duplicateSource}
        </Link>
      ),
    },
    {
      id: 'isSharingAllowed',
      Header: 'Sharing',
      accessor: (d) => d.isSharingAllowed,
      sortable: false,
      width: 60
    },
    {
      id: 'areStudentsAllowed',
      Header: 'Students',
      accessor: (d) => d.areStudentsAllowed,
      sortable: false,
      width: 60
    },
    {
      id: 'isParkingSpotOrGarage',
      Header: 'IsParking',
      accessor: (d) => d.isParkingSpotOrGarage.toString(),
      sortable: false,
      width: 60,
      filterable: false
    },
    {
      id: 'createDate', Header: 'Created', accessor: (d) => d.createDate, filterable: true,
      width: 190,
    },
    {
      id: 'modifiedDate',
      Header: 'Modified',
      accessor: (d) => d.modifiedDate,
      filterable: true,
      width: 190,
    },
    {
      id: 'imageUrl',
      Header: 'Image',
      accessor: (d) => d.imageUrl,
      filterable: false,
      width: 190,
      Cell: (props) => (
        <a target="_blank" rel="noreferrer" href={props.value}> {props.value} </a>),
    },
    {
      id: 'agentRunId', Header: 'agentRunId', accessor: (d) => d.agentRunId, filterable: true,
      width: 300,
    },
  ]

  fetchData = async (state, instance) => {
    var self = this
    var delayInMilliseconds = 1500 //1 second
    clearTimeout(this.delayTimer)
    this.delayTimer = setTimeout(async function () {
      await self.doFetchData(self, state)
    }, delayInMilliseconds)
  }

  doFetchData = async (self, state) => {
    self.setState({ loading: true })
    const { pageSize, page, sorted, filtered } = state
    const url = self.props.getUrl()
    const { data } = await http.post(url, { pageSize, page, sorted, filtered, })
    self.setState({ data: data.rows, pages: data.pages, loading: false, })
  }

  render () {
    const { data, loading, pages } = this.state
    const columns = this.columns
    const defaultPageSize = this.props.defaultPageSize || 20
    return (
      <ReactTable
        manual
        className="my-4"
        data={data}
        pages={pages}
        loading={loading}
        columns={columns}
        defaultPageSize={defaultPageSize}
        filterable
        onFetchData={this.fetchData}
      />
    )
  }
}

export default ParsedHousesTable