import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Link } from 'react-router-dom'

class ParserDefinitionsTable extends Component {
  getColumns = () => {
    return [
      {
        id: 'edit', Header: '#', accessor: d => 'Edit', filterable: false,
        sortable: false, width: 60,
        Cell: p =>
          <Link to={`/parserdefinitions/${this.props.data[p.index].id}`}>
            {p.value}
          </Link>
      },
      {
        id: 'name', Header: 'name', accessor: d => d.name,
        filterable: true,
      },
      {
        id: 'state',
        Header: 'state',
        accessor: d => d.state,
        sortable: false,
        filterable: true,
      },
      {
        id: 'isPaidSource',
        Header: 'IsPaidSource',
        accessor: d => d.isPaidSource.toString(),
        sortable: false,
        filterable: true,
      },
      {
        id: 'logs', Header: 'Logs', accessor: d => d.name, filterable: false, sortable: false,
        Cell: p =>
          <a href={`https://rs-scraper.azurewebsites.net/logs/rentslam/${p.value}/`} target="_blank"
             rel="noopener noreferrer">
            {p.value}
          </a>
      },
      {
        id: 'order', Header: 'Order', accessor: d => d.order, filterable: false,
        width: 90
      },
      {
        id: 'createDate', Header: 'Create date', accessor: d => d.createDate,
        filterable: false, width: 190, sortable: true
      },
      {
        id: 'modifiedDate', Header: 'Modified date', accessor: 'modifiedDate',
        filterable: false, width: 190, sortable: true
      }
    ]
  }

  render () {
    // return (
    //   <EntityTable url="parserdefinitions" columns={this.getColumns()} linkToEditIndex="0"
    //   />
    // );
    // return (
    //   <ReactTable
    //     className="my-4"
    //     data={this.state.data}
    //     pages={this.state.pages}
    //     loading={this.state.loading}
    //     columns={this.getColumns()}
    //     filterable
    //     onFetchData={this.fetchData}
    //     // minRows={this.props.minRows}
    //   />
    // );
    return <ReactTable columns={this.getColumns()} data={this.props.data}></ReactTable>
  }
}

export default ParserDefinitionsTable
