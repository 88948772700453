import React from 'react'

const AnalysisResult = ({ result }) => {
  if (!result) return <p>No results</p>
  if (result.error) return <p>{result.error}</p>
  return (
  <div>
      <>
        <h2>Technology</h2>
        <p>{result.technology}</p>
        <h2>Agent</h2>
        <p>{result.usedByAgentResult}</p>
      </>
  </div>
)}

export default AnalysisResult