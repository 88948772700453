import React, { Component } from "react";
import exportService from "../../services/csv";
import { Table, Button } from "reactstrap";

class CandidatesTable extends Component {
  handleExport = () => {
    var rows = [];
    rows.push(["firstName", "email", "phoneNumber"]);
    this.props.candidates.map(c =>
      rows.push([c.firstName, c.email, c.phoneNumber])
    );
    exportService.exportToCsv("export.csv", rows);
  };

  render() {
    return this.props.candidates ? (
      <React.Fragment>
        <h1>Candidates</h1>
        <Button className="my-3" onClick={this.handleExport}>
          Export
        </Button>
        <Table>
          <thead>
            <tr>
              <td>first name</td>
              <td>email</td>
              <td>PhoneNumber</td>
            </tr>
          </thead>
          <tbody>
            {this.props.candidates.map(c => (
              <tr key={c.id}>
                <td>{c.firstName}</td>
                <td>{c.email}</td>
                <td>{c.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    ) : null;
  }
}

export default CandidatesTable;
