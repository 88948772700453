import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Button, Container } from 'reactstrap'
import { toast } from 'react-toastify'
import { CitiesClient } from '../../utils/api.js'
import CityForm from './cityForm'
import DefaultPanelContainer from './../common/defaultPanelContainer'

class Cities extends Component {
  state = { data: [], changed: new Set([]) }

  client = new CitiesClient()

  async componentDidMount () {
    const data = await this.client.get()
    this.setState({ data })
  }

  handleClick = (props) => {
    if (this.state.changed.size > 0) {
      this.state.changed.forEach((element) => {
        this.client
          .put(element)
          .then(() => toast.success('Changes saved'))
          .catch((err) => toast.error('Changes not saved'))
      })

      this.setState({ changed: new Set([]) })
    }
  }
  renderSelect = (cellInfo) => {
    console.log(cellInfo)
    return (
      <select
        suppressContentEditableWarning
        onChange={e => {
          const { data, changed } = this.state
          if (data[cellInfo.index][cellInfo.column.id] !== e.target.value) {
            data[cellInfo.index][cellInfo.column.id] = e.target.value
            changed.add(data[cellInfo.index])
            this.setState({ data, changed })
          }
        }}
        style={{ width: '100%' }}
        value={cellInfo.value}
      >
        <option value="true">True</option>
        <option value="false">False</option>
      </select>

    )
  }
  //https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-editable-content
  renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: '#fafafa', whiteSpace: 'normal' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const { data, changed } = this.state
          if (data[cellInfo.index][cellInfo.column.id] !== e.target.innerHTML) {
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML
            changed.add(data[cellInfo.index])
            this.setState({ data, changed })
          }
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.data[cellInfo.index][cellInfo.column.id],
        }}
      />
    )
  }

  getColumns = () => {
    return [
      {
        id: 'name',
        Header: 'name',
        accessor: (d) => d.name,
        width: 180,
        Cell: this.renderEditable,
      },
      {
        id: 'displayName',
        Header: 'displayName',
        accessor: (d) => d.displayName,
        width: 300,
        Cell: this.renderEditable,
      },
      {
        id: 'searchTermsCsv',
        Header: 'searchTermsCsv',
        accessor: (d) => d.searchTermsCsv,
        sortable: false,
        Cell: this.renderEditable,
      },
      {
        id: 'disabled',
        Header: 'disabled',
        accessor: (d) => d.disabled.toString(),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="">All</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        ),
        width: 90,
        Cell: this.renderSelect,
      },
      {
        id: 'coordinates',
        Header: 'Coordinates',
        accessor: (d) => `${d.latitude}, ${d.longitude}`,
        width: 180,
      },
    ]
  }

  render () {
    const { loading, data } = this.state
    return (
      <Container>
        <ReactTable
          defaultPageSize={30}
          className="my-4"
          data={data}
          loading={loading}
          filterable
          pageSizeOptions={[30, 50, 100]}
          columns={this.getColumns()}
          onFetchData={this.fetchData}
          minRows="3"
        />
        {
          <Button
            onClick={this.handleClick}
            disabled={this.state.changed.size === 0}
            className="btn btn-primary"
          >
            Save
          </Button>
        }
        <DefaultPanelContainer title="Create city">
          <CityForm/>
        </DefaultPanelContainer>
      </Container>
    )
  }
}

export default Cities
