import React, {useEffect, useState} from "react";
import {Container, Row, Col, Button} from "reactstrap";
import http from "../../services/httpService";
import {Link} from "react-router-dom";

export default function CustomerFeedbackDetails(props) {
    const [customerFeedback, setCustomerFeedback] = useState({feedback: "loading"});
    useEffect(() => {
        (async () => {
            const customerFeedbackId = props.match.params.id;
            const {data: customerFeedback} = await http.get(`customerfeedback/${customerFeedbackId}`);
            setCustomerFeedback(customerFeedback);
        })();
    }, [])
    return (<Container>
        <h1>Customer feedback</h1>
        <Row className="my-4">
            <Col>
                <Link to={`/users/${customerFeedback.userId}`}> {customerFeedback.name} </Link>
            </Col>
        </Row>
        <Row>
            <Col>
                {customerFeedback.feedback}
            </Col>
        </Row>
        <Row>
            <Col>
                <Button onClick={props.history.goBack}>Back</Button>
            </Col>
        </Row>
    </Container>)
}