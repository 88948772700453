import React, {Component} from "react";
import { toast } from "react-toastify";
import BaseForm from "./../common/baseForm";
import SubscriptionsTable from "./../subscriptionsTable";
import BillingProfile from "./../billingProfile";
import UserParsedHouseEventsTable from "./userParsedHousesEventsTable";
import DefaultPanelContainer from "./../common/defaultPanelContainer";
import auth from "./../../services/authService"
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import http from "../../services/httpService";
import PaymentsTable from "./../paymentsTable";
import SearchProfilesTable from "./../searchProfilesTable";
import UserForm from "./userForm";
import {AdminPermission, CustomerServicePermission} from "../adminPermission";
import UserCustomerServiceForm from "./userCustomerServiceForm";

class UserDetails extends Component {
  state = {
    isLoaded: false,
    tree: [],
    billingProfile: {}
  };

  loadBillingProfile = async () => {
    const { data } = await http.get(
      "billingprofiles/" + this.props.match.params.id
    );

    this.setState({ billingProfile: data });
  };

  async componentDidMount() {
    await this.loadBillingProfile();
  }


  doUnsubscribe = async (confirmationMessage, path, successMessage) => {
    if (window.confirm(confirmationMessage)) {
      await http.put(path + this.props.match.params.id);
      toast.success(successMessage);
      // await this.loadSearchProfile(data);
      await this.loadBillingProfile();

      this.setState({ loading: false });
    }
  };

  handleUnsubscribeClick = async () => {
    await this.doUnsubscribe(
      "Are you sure you want to unsubscribe this user",
      "users/unsubscribe/",
      "user sucessfully unsubscribed"
    );
  };

  handleUnsubscribeAndNotifyClick = async () => {
    await this.doUnsubscribe(
      "Are you sure you want to unsubscribe and notify this user by email",
      "users/unsubscribeandnotify/",
      "user sucessfully unsubscribed and notified"
    );
  };

  handleDeleteForeverClick = async () => {
    if (window.confirm("Are you sure. This operation cannot be undone")) {
      try {
        await http.delete("users/delete/" + this.props.match.params.id);
        toast.success("user sucessfully deleted");
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.username = ex.response.data;
          this.setState({ errors });
        }
      }
    }
  };

  handleToggleAutorenewals = async () => {
    if (window.confirm("do you want to toggle autorenewals?")) {
      await http.post("billingprofiles/toggle/" + this.props.match.params.id);
      await this.loadBillingProfile();
      toast.success("billing profile successfully toggled");
    }
  };

  render() {
    // if (!this.state.isLoaded) return null;
    const id = this.props.match.params.id;

    return (
      <Container>
        <AdminPermission user={auth.getCurrentUser()}>
          <UserForm  userId={id} />
        </AdminPermission>
        <CustomerServicePermission user={auth.getCurrentUser()}>
          <UserCustomerServiceForm userId={id}/>
        </CustomerServicePermission>
        <Row>
          <Col>
            <SearchProfilesTable userId={id} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DefaultPanelContainer title="Payments">
              <PaymentsTable minRows={4} endpoint={"payments/user/" + id} />
            </DefaultPanelContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubscriptionsTable userId={id} />
          </Col>
        </Row>
        <Row>
          <Col>
            <BillingProfile
              billingProfile={this.state.billingProfile}
              // onCancel={this.handleCancelBillingProfileClick}
              onToggleAutorenewals={this.handleToggleAutorenewals}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <UserParsedHouseEventsTable
              title="Parsed houses"
              getUrl={() => "userparsedhouseevent/user/" + this.props.match.params.id } 
            userId={this.props.match.params.id}/>
          </Col>
        </Row>
        <Row>
          <Col className="my-3 col-2">
            <Button color="danger" onClick={this.handleUnsubscribeClick}> Unsubscribe </Button>
          </Col>
          <Col className="my-3 col-2">
            <Button color="danger" onClick={this.handleUnsubscribeAndNotifyClick} >
              Unsubscribe and notify user
            </Button>
          </Col>
          <Col className="my-3 col-2">
            <Button color="danger" onClick={this.handleDeleteForeverClick}> Delete forever </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default UserDetails;