import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import http from '../../services/httpService'
import { Link } from 'react-router-dom'

class MatchedUsersTable extends Component {
  state = {
    data: [],
    pages: null,
    loading: true
  }
  columns = [
    {
      id: 'edit',
      Header: '#',
      accessor: d => 'Edit',
      filterable: false,
      sortable: false,
      width: 40,
      Cell: props => (
        <Link to={`/users/${this.state.data[props.index].id}`}>
          {props.value}
        </Link>
      )
    },
    {
      id: 'matchType',
      Header: 'Match Type',
      accessor: d => d.matchType,
      width: 80
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: d => d.name,
      width: 230
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: d => d.email,
      width: 200
    },
    {
      id: 'createDate',
      Header: 'Created',
      accessor: d => d.createDate,
      filterable: true,
      width: 190
    },
    {
      id: 'modifiedDate',
      Header: 'Modified',
      accessor: d => d.modifiedDate,
      filterable: true,
      width: 190
    },
    {
      id: 'diagnostics', Header: 'Diagnostics', filterable: true, width: 200,
      Cell: props => (
        <Link
          to={`/diagnostics?userId=${this.state.data[props.index].id}&parsedHouseId=${this.props.parsedHouseId}`}>
          Run Diagnostics
        </Link>
      )
    },
  ]

  fetchData = async (state, instance) => {
    this.setState({ loading: true })

    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    const { pageSize, page, sorted, filtered } = state
    const url = this.props.getUrl()
    const { data } = await http.post(url, {
      pageSize,
      page,
      sorted,
      filtered
    })

    this.setState({
      data: data.rows,
      pages: data.pages,
      loading: false
    })
  }

  render () {
    const { data, loading, pages } = this.state
    const columns = this.columns
    return (
      <ReactTable
        manual
        className="my-4"
        data={data}
        pages={pages}
        loading={loading}
        columns={columns}
        filterable
        onFetchData={this.fetchData}
      />
    )
  }
}

export default MatchedUsersTable
