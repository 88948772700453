import React, { Component } from "react";
import BaseForm from "./../common/baseForm";
import { Row, Col, Button, FormGroup, Form, Container } from "reactstrap";
import Joi from "joi-browser";
import { PostcodesClient } from "../../utils/api.js";
import { toast } from "react-toastify";
import http from "../../services/httpService";
class PostcodeForm extends BaseForm {
  state = {
    data: {
      cityId: "",
      parentId: "",
      name: "",
      zipCodesCsv: "",
      isSearchable: false
    },
    errors: {},
    cities: [],
    parents: [],
  };
  client = new PostcodesClient();
  schema = {
    name: Joi.required(),
    cityId: Joi.required(),
    isSearchable: Joi.required(),
    parentId: Joi.optional(),
    zipCodesCsv: Joi.required(),
    id: Joi.optional(),
    createDate: Joi.optional(),
    modifiedDate: Joi.optional(),
  };

  updateParents = (cityId) => {
    let parents = [];
    this.client.findByCity(cityId).then((r) => {
      parents = r.map((i) => {
        return { name: i.name, value: i.id };
      });
      this.setState({ parents });
    });
  };

  async componentDidMount() {
    const { data: cities } = await http.get("cities/selectalllist");
    let id = this.props.match.params.id;

    if (id !== "new") {
      this.client.get(id).then((r) => {
        if (r.parentId === null) {
          r.parentId = "";
        }
        this.setState({
          data: {
            id: r.id,
            cityId: r.cityId,
            isSearchable: r.isSearchable,
            parentId: r.parentId,
            name: r.name,
            zipCodesCsv: r.zipCodesCsv,
          },
        });
        this.updateParents(r.cityId);
      });
    } else {
      const { data } = this.state;
      data.cityId = cities[0].value;
      this.setState({ data });
      this.updateParents(data.cityId);
    }

    this.setState({ cities });
  }

  handleCityChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.updateParents(input.value);
    this.setState({ data });
  };

  doSubmit = async () => {
    if (this.state.data.id) {
      this.client
        .put(this.state.data)
        .then(() => toast.success("postcode saved"))
        .catch((err) => toast.error("Error saving postcode: " + err));
    } else {
      this.client
        .post(this.state.data)
        .then(() => toast.success("New postcode created"))
        .catch((err) => toast.error("Error creating postcode: " + err));
    }
  };
  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>{this.renderInput("name", "Name")}</Col>
          </Row>
          <Row>
            <Col>
              {this.renderSelect( "cityId", "City", this.state.cities,
                  this.handleCityChange
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderSelect( "isSearchable", "Searchable", 
                  [{"name":"true", "value":"true"}, {"name":"false", "value":"false"}]
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderSelect("parentId", "Parent", this.state.parents)}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderInput( "zipCodesCsv", "Postcodes (comma separated list)" )}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderButton("save")}&nbsp;
              <Button onClick={this.props.history.goBack} color="danger">
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default PostcodeForm;
