import React, {Component} from "react";
import {Button, Card, CardBody, Col, Container, Row,} from "reactstrap";
import http from "../../services/httpService";
import auth from "../../services/authService";
import ReactTable from "react-table";
import {AdminPermission} from "../adminPermission";
import {RefreshStats} from "./refreshStats";
import {
    firstDayOfTheMonthDate,
    formatDate,
    getNewCustomersStat
} from "../../services/dashboardService";
import {DateRangeSearch} from "../common/dateRange";
import {SingleStat} from "./singleStat";

class Dashboard extends Component {
    state = {
        stats: [],
        startDate: firstDayOfTheMonthDate(),
        endDate: formatDate(new Date()),
        newCustomers: {},
        isLoading: true,
        year: new Date().getFullYear(),
    }

    async componentDidMount() {
        try {
            if (!auth.isAdmin())
                return;
            const {startDate, endDate} = this.getDateRangeFromUrl();
            const newCustomers = await getNewCustomersStat(startDate, endDate);
            const {data: stats} = await http.get(`dashboard?start=${startDate}&end=${endDate}`);
            this.setState({startDate, newCustomers, endDate, stats, isLoading: false});
        } catch (ex) {
            if (ex.response && ex.response.status === 401) {
                auth.logout();
                window.location.reload(true);
            }
            if (ex.response && ex.response.status === 404) alert("Can't find stats");
        }
    }

    getDateRangeFromUrl = () => {
        //get start and end from querystring
        const params = new URLSearchParams(this.props.location.search);
        const startDate = params.get('start') ? params.get('start') : firstDayOfTheMonthDate();
        const endDate = params.get('end') ? params.get('end') : formatDate(new Date());
        return {startDate, endDate};
    };

    handleRefresh = async () => {
        try {
            await this.handleGetStats();
        } catch (ex) {
            if (ex.response && ex.response.status === 404) alert("Can't find stats");
        }
    };

    handleDateChange = async (e) => {
        this.setState({[e.target.id]: e.target.value});
    };
    handleDatesChange = async (startDate, endDate) => {
        this.setState({isLoading: true});
        const {data: stats} = await http.get(`dashboard?start=${startDate}&end=${endDate}`);
        const newCustomers = await getNewCustomersStat(startDate, endDate);
        this.setState({startDate, endDate, newCustomers, stats, isLoading: false});
    };

    handleGetStats = async () => {
        this.setState({isLoading: true});
        // this.setState({newCustomers: {}});
        const {startDate, endDate} = this.state;
        const newCustomers = await getNewCustomersStat(startDate, endDate);
        const {data: stats} = await http.get(`dashboard?start=${startDate}&end=${endDate}`);
        this.setState({stats, newCustomers, isLoading: false});
    };

    render() {
        const {isLoading, newCustomers, startDate, endDate} = this.state;
        const columns = [
            {id: "cityName", Header: "City", accessor: d => d.cityName},
            {id: "sales", Header: "Sales", accessor: d => d.salesToday},
            {
                id: "newCustomersToday",
                Header: "New customers",
                accessor: d => d.newCustomersToday
            },
            {
                id: "searchProfilesCount",
                Header: "Total Search profiles",
                accessor: d => d.searchProfilesCount
            },
            {
                id: "activeCustomers",
                Header: "Total Active customers",
                accessor: d => d.activeCustomers
            },
            {
                id: "parsedHouses",
                Header: "Parsed houses",
                accessor: d => d.parsedHousesToday
            },
            {
                id: "successRateThisMonth",
                Header: "Success rate",
                accessor: d => d.successRateThisMonth
            }
        ];
        return (
            <Container>
                <AdminPermission user={auth.getCurrentUser()}>
                    <Row className={"mt-2"}>
                        <Col>
                            <DateRangeSearch startDate={startDate} endDate={endDate}
                                             onDateChange={this.handleDateChange}
                                             onSearch={this.handleGetStats}/>
                        </Col>
                    </Row>
                    <Row className={"my-2"}>
                        <Col>
                            <Button
                                onClick={() =>
                                    this.handleDatesChange(formatDate(new Date()), formatDate(new Date()))
                                }>Today

                            </Button>
                            <Button className={"ml-2"}
                                    onClick={() =>
                                        this.handleDatesChange(firstDayOfTheMonthDate(), formatDate(new Date()))
                                    }>This month

                            </Button>
                            <Button className={"ml-2"}
                                    onClick={() =>
                                        this.handleDatesChange(formatDate(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 3)), firstDayOfTheMonthDate())
                                    }>Last month

                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="2">
                            <RefreshStats handleRefresh={this.handleRefresh}
                                          isLoading={isLoading}/>
                        </Col>
                    </Row>
                    <Row className={"mt-2"}>
                        <Col>
                            <Card style={{maxWidth: "200px"}}>
                                <CardBody>
                                    {Object.keys(newCustomers).map((key, i) =>
                                        <SingleStat className={"my-1"} key={i} isLoading={isLoading}
                                                    name={key}
                                                    value={newCustomers[key]}/>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {!isLoading && (
                        <ReactTable
                            defaultPageSize={30}
                            className="my-3"
                            data={this.state.stats}
                            loading={isLoading}
                            columns={columns}
                            filterable
                            pageSizeOptions={[30, 50, 100]}
                            minRows={30}
                        />
                    )}
                    {/*<QuarterlyReportForm/>*/}
                </AdminPermission>
            </Container>
        );
    }
}

export default Dashboard;