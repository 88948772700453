import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const UserRolesInput = ({ roles, userRoleNames, errors, onRolesChange, disabled = false}) => {
  return (
    <React.Fragment>
      <h5>Roles</h5>
      {roles.map(role => (
        <FormGroup key={role.item1} check>
          <Label check>
            <Input
              type="checkbox"
              name="userRoleNames"
              id={role.item2}
              label={role.item2}
              value={role.item2}
              checked={
                userRoleNames &&
                userRoleNames.filter(id => id === role.item2).length > 0
              }
              onChange={onRolesChange}
              disabled={disabled}
            />{" "}
            {role.item2}
          </Label>
        </FormGroup>
      ))}
      {errors["userRoleNames"] && (
        <div className="alert alert-danger">{errors["userRoleNames"]}</div>
      )}
    </React.Fragment>
  );
};

export default UserRolesInput;
