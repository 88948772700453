import http from "./httpService";
import auth from "./authService";

export const formatDate = (date) => {
    return date.toISOString().split("T")[0];
};

export const firstDayOfTheMonthDate = () => {
    const date = new Date();
    return formatDate(new Date(date.getFullYear(), date.getMonth(), 1, 3));
};

export async function getNewCustomersStat(startDate, endDate) {
    try {
        const {data: stats} = await http.get(
            `dashboard/customerservicedashboard?start=${startDate}&end=${endDate}`);
        return stats;
    } catch (ex) {
        if (ex.response && ex.response.status === 401) {
            auth.logout();
            window.location.reload(true);
        }
        if (ex.response && ex.response.status === 404) alert("Can't find stats");
    }
}