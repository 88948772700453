import React, { Component } from "react";
import { Link } from "react-router-dom";
import entityTableService from "../../services/entityTableService";
import ReactTable from "react-table";

class CustomerFeedbackTable extends Component {
  state = {
    data: [],
    pages: null,
    loading: true,
  };

  fetchData = async (state, instance) => {
    var self = this;
    var delayInMilliseconds = 1500; //1 second
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(async function() {
      await self.doFetchData(self, state);
    }, delayInMilliseconds);
  };

  doFetchData = async (self, state) => {
    self.setState({ loading: true });
    const data = await entityTableService.fetchTableData(state, "customerfeedback");

    self.setState({
      data: data.rows,
      pages: data.pages,
      loading: false,
    });
  };

  getColumns = () => {
    return [
      {
        id: "edit", Header: "#", accessor: d => "Edit", filterable: false, sortable: false,
        width: 60, Cell: props => (
            <Link to={`/customerfeedback/${this.state.data[props.index].id}`}>
              {props.value}
            </Link>
        )
      },
      {
        id: "name", Header: "name", accessor: d => d.name, width: 150,
         Cell: props => (
           <Link to={`/users/${this.state.data[props.index].userId}`}> {props.value} </Link>
         )
      },
      { id: "culture", Header: "culture", accessor: d => d.culture, width: 30 },
      { id: "email", Header: "Email", accessor: d => d.email, sortable: false, width: 200 },
      {
        id: "reason", Header: "reason", accessor: d => d.reason, filterable: true, sortable: false,
        width: 150, style: { whiteSpace: "unset" }, Cell: props => <div>{props.value}</div>
      },
      {
        id: "feedback", Header: "feedback", accessor: d => d.feedback, filterable: true, sortable: false,
        style: { whiteSpace: "unset" }, Cell: props => <div>{props.value}</div>
      },
      {
        id: "createDate", Header: "Create date", accessor: d => d.createDate, filterable: false, width: 190
      },
      {
        id: "modifiedDate", Header: "Modified date", accessor: "modifiedDate", filterable: false, width: 190
      }
    ];
  };
  render() {
    const { data, loading, pages } = this.state;
    const columns = this.getColumns();
    return (
        <ReactTable manual className="my-4" data={data} pages={pages}
            loading={loading} columns={columns} filterable onFetchData={this.fetchData}
            minRows={this.props.minRows}
        />
    );
  }
}

export default CustomerFeedbackTable;
