import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import http from "../../services/httpService";
import { Link } from "react-router-dom";
class ReportedProblemsTable extends Component {
    state = { data: [], pages: null, loading: true, };
    delayTimer;

    fetchData = async (state, instance) => {
        var self = this;
        var delayInMilliseconds = 1500; //1 second
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async function () {
            await self.doFetchData(self, state);
        }, delayInMilliseconds);
    };

    doFetchData = async (self, state) => {
        self.setState({ loading: true });
        const { pageSize, page, sorted, filtered } = state;
        const url = self.props.getUrl();
        const { data } = await http.get(url, { params: { pageSize, page, sorted, filtered, }});
        self.setState({ data: data.rows, pages: data.pages, loading: false, });
    };

    columns = [
        {
            id: "edit", Header: "Edit",
            accessor: () => "Edit", filterable: false, sortable: false, width: 40,
            Cell: (props) => (
                <Link to={`/parsedhouses/${this.state.data[props.index].parsedHouseId}`}> {props.value} </Link>
            ),
        },
        {
            id: "url", Header: "Link", 
            accessor: (d) => d.address, filterable: false, sortable: false, width: 140,
            Cell: (props) => (
                <a href={this.state.data[props.index].url}> {props.value} </a>
            ),
        },
        { id: "userEmail", Header: "UserEmail", accessor: (d) => d.userEmail, width: 230,
            Cell: (props) => (
                <Link to={`/users/${this.state.data[props.index].userId}`}> {props.value} </Link>
            ),
        },
        { id: "problemType", Header: "ProblemType", accessor: (d) => d.problemType, width: 230 },
        { id: "explanation", Header: "Explanation", accessor: (d) => d.explanation, width: 430, },
        { id: "createDate", Header: "Created", accessor: (d) => d.createDate, filterable: true,
            width: 190,
        },
        { id: "modifiedDate", Header: "Modified", accessor: (d) => d.modifiedDate, filterable: true,
            width: 190,
        },
        { id: "diagnostics", Header: "Diagnostics", filterable: true, width: 200,
            Cell: props => (
                <Link to={`/diagnostics?userid=${this.state.data[props.index].userId}&parsedHouseId=${this.state.data[props.index].parsedHouseId}`}>
                    Run Diagnostics
                </Link>
            )},
        ]
    
    render() {
        const { data, loading, pages } = this.state;
        const columns = this.columns;
        return (
            <ReactTable
                manual
                className="my-4"
                data={data}
                pages={pages}
                loading={loading}
                columns={columns}
                filterable
                onFetchData={this.fetchData}
            />
        );
    }

}

export default ReportedProblemsTable