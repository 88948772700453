import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import http from "../services/httpService";

class SubscriptionsTable extends Component {
  state = { data: [], loading: true };

  async componentDidMount() {
    const { data } = await http.get("userSubscriptions/" + this.props.userId);

    this.setState({ data, loading: false });
  }

  renderCreateUserSubscription = () => {
    return (
      <Row>
        <Col className="my-3">
          <Link
            to={`/users/${this.props.userId}/usersubscriptions/new`}
            className="btn btn-primary"
          >
            Create subscription
          </Link>
        </Col>
      </Row>
    );
  };

  render() {
    const { data, loading } = this.state;
    if (loading) return <p>Loading...</p>;
    if (!data || data.length === 0) return this.renderCreateUserSubscription();

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Card className="my-3">
              <Row>
                <Col>
                  <CardHeader>
                    <strong>Subscriptions</strong>
                  </CardHeader>
                </Col>
              </Row>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map(s => (
                      <tr key={s.id}>
                        <td>
                          <Link
                            to={`/users/${
                              this.props.userId
                            }/usersubscriptions/${s.id}`}
                          >
                            {s.name}
                          </Link>
                        </td>
                        <td>{s.type}</td>
                        <td>{s.startDate}</td>
                        <td>{s.endDate}</td>
                        <td>{s.comments}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.renderCreateUserSubscription()}
      </React.Fragment>
    );
  }
}

export default SubscriptionsTable;
