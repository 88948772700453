import React, {Component} from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";

import http from "../../services/httpService";

class AgentStatus extends Component {
    state = {
        isLoading: true,
        agentIsRunning: false,
        isActive: false,
        lastRun: ""
    };

    async componentDidMount() {
        await this.updateStatus();
    }

    async updateStatus() {
        try {
            const {data} = await http.get("dashboard/agentstatus");
            this.setState({
                agentIsRunning: data.isRunning,
                isActive: data.isActive,
                lastRun: data.lastRun
            });
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                alert("Can't find agent status");
        }
    }

    handleStartAgent = async () => {
        await http.get("dashboard/startagent");
        this.setState({agentIsRunning: true, isActive: true});
    };

    handleStopAgent = async () => {
        await http.get("dashboard/stopagent");
        this.setState({isActive: false});
    };

    render() {
        const {agentIsRunning, isActive, lastRun} = this.state;
        return (
            <Card className="my-3">
                <CardBody>
                    <Row className="mb-3">
                        <Col>
                            Last run: {lastRun}<br/>
                            Agent status:&nbsp;
                            {agentIsRunning ? (
                                <span className="text-success">
                  <strong>running</strong>
                </span>
                            ) : isActive ? (
                                <span className="text-info">
                  <strong>waiting</strong>
                </span>
                            ) : (
                                <span className="text-warning">
                  <strong>stopped</strong>
                </span>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isActive ? (
                                <Button color="danger" onClick={this.handleStopAgent}>
                                    Stop agent
                                </Button>
                            ) : (
                                <Button color="success" onClick={this.handleStartAgent}>
                                    Start agent
                                </Button>
                            )}
                            {
                                <Button color="info" className="ml-1" onClick={this.updateStatus}>
                                    Refresh
                                </Button>}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default AgentStatus;
