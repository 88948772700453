import React from "react";
import Joi from "joi-browser";
import auth from "../services/authService";
import BaseForm from "./common/baseForm";
import { Container, Row, Col, Form } from "reactstrap";

class LoginForm extends BaseForm {
  state = {
    data: {
      username: "",
      password: ""
    },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.username, data.password);
      // this.props.history.push("/");
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <h1>Login</h1>
            <Form onSubmit={this.handleSubmit}>
              {this.renderInput("username", "Username")}
              {this.renderInput("password", "Password", "password")}
              {this.renderButton("Login")}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginForm;
