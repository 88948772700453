import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { toast } from "react-toastify";
import http from "../services/httpService";
import { Link } from "react-router-dom";

class PaymentDetails extends Component {
  state = {
    data: {}
  };

  async componentDidMount() {
    const { data } = await http.get("payments/" + this.props.match.params.id);

    this.setState({
      data
    });
  }

  doRefund = async url => {
    const { data: result } = await http.post(url);
    console.log("refund", result);
    if (result.status === "success") {
      toast.success(result.message);
      const { data } = this.state;

      data.refundDate = result.refundDate;

      this.setState({ data });
    } else {
      toast.error(result.message);
    }
  };

  handleRefund = async paymentId => {
    await this.doRefund(`payments/refund/${paymentId}`);
  };

  handleMarkAsRefunded = async paymentId => {
    await this.doRefund(`payments/markasrefunded/${paymentId}`);
  };

  renderPaymentDetailItem = (label, value, item = <span>{value}</span>) => {
    return (
      <Row>
        <Col sm="2">
          <strong>{label}</strong>
        </Col>
        <Col>{item}</Col>
      </Row>
    );
  };

  render() {
    const {
      id,
      externalPaymentId,
      paymentMethod,
      subscriptionName,
      price,
      userName,
      createDate,
      modifiedDate,
      refundDate,
      paymentDate,
      userId
    } = this.state.data;

    return (
      <Container>
        <Row>
          <Col>
            <Card className="my-4">
              <CardHeader>
                <CardTitle className="mt-2">Payment details</CardTitle>
              </CardHeader>
              <CardBody>
                {this.renderPaymentDetailItem(
                  "user:",
                  userName,
                  <Link to={`/users/${userId}`}>{userName}</Link>
                )}
                {this.renderPaymentDetailItem("Price:", "€" + price)}
                {this.renderPaymentDetailItem(
                  "Stripe payment Id:",
                  externalPaymentId,
                  <a
                    href={`${
                      process.env.REACT_APP_STRIPE_PAYMENTS_URL
                    }${externalPaymentId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {externalPaymentId}
                  </a>
                )}
                {this.renderPaymentDetailItem(
                  "Subscription:",
                  subscriptionName
                )}
                {this.renderPaymentDetailItem("Payment method:", paymentMethod)}
                {this.renderPaymentDetailItem("Payment date:", paymentDate)}
                {this.renderPaymentDetailItem("Refund date:", refundDate)}
                {this.renderPaymentDetailItem("Created date:", createDate)}
                {this.renderPaymentDetailItem("Modified date:", modifiedDate)}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Button
                  className="mx-2"
                  color="danger"
                  onClick={() => this.handleRefund(id)}
                  disabled={refundDate && refundDate !== null}
                >
                  Refund
                </Button>
                <Button
                  className="mx-2"
                  color="danger"
                  onClick={() => this.handleMarkAsRefunded(id)}
                  disabled={refundDate && refundDate !== null}
                >
                  Mark as refunded
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PaymentDetails;
