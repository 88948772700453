import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Container, Button } from "reactstrap";
import { toast } from "react-toastify";
import entityTableService from "../../services/entityTableService";
import { Link } from "react-router-dom";
import LinkButton from "./../common/linkButton";
import { PostcodesClient } from "../../utils/api.js";

class Postcodes extends Component {
  state = { data: [], pages: null, loading: true, changed: new Set([]) };

  client = new PostcodesClient();
  
  handleClick = (props) => {
    if (this.state.changed.size > 0) {
      this.state.changed.forEach((element) => {
        this.client
          .put(element)
          .then(() => toast.success("Changes saved"))
          .catch((err) => toast.error("Changes not saved"));
      });

      this.setState({ changed: new Set([]) });
    }
  };
  fetchData = async (state, instance) => {
    var self = this;
    var delayInMilliseconds = 1500; //1 second
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(async function() {
      await self.doFetchData(self, state);
    }, delayInMilliseconds);
  };

  doFetchData = async (self, state) => {
    self.setState({ loading: true });

    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    const data = await entityTableService.fetchTableData(
      state,
      "postcodes/find"
    );

    self.setState({
      data: data.rows,
      pages: data.pages,
      loading: false,
    });
  };

  //https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-editable-content
  renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa", whiteSpace: "normal" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const { data, changed } = this.state;
          if (data[cellInfo.index][cellInfo.column.id] !== e.target.innerHTML) {
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            changed.add(data[cellInfo.index]);
            this.setState({ data, changed });
          }
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.data[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  };

  getColumns = () => {
    return [
      {
        id: "edit",
        Header: "#",
        accessor: (d) => "Edit",
        filterable: false,
        sortable: false,
        width: 40,
        Cell: (props) => (
          <Link to={`/postcodes/${this.state.data[props.index].id}`}>
            {props.value}
          </Link>
        ),
      },
      {
        id: "city", Header: "city", accessor: (d) => d.cityName, width: 180,
      },
      {
        id: "name", Header: "name", accessor: (d) => d.name, width: 180, Cell: this.renderEditable,
      },
      {
        id: "parent", Header: "Parent", accessor: (d) => d.parentName, width: 180,
      },
      {
        id: "searchable", Header: "Searchable", accessor: (d) => d.isSearchable.toString(), width: 80,
      },
      {
        id: "zipCodesCsv", Header: "zipCodesCsv", accessor: (d) => d.zipCodesCsv, sortable: false,
        Cell: this.renderEditable,
      },
    ];
  };

  render() {
    const { data, loading, pages } = this.state;
    return (
      <Container>
        <LinkButton text="create" url={`/postcodes/new`} />

        <ReactTable
          manual
          filterable
          className="my-4"
          data={data}
          pages={pages}
          loading={loading}
          columns={this.getColumns()}
          onFetchData={this.fetchData}
          minRows="3"
        />
        {
          <Button
            onClick={this.handleClick}
            disabled={this.state.changed.size === 0}
            className="btn btn-primary"
          >
            Save
          </Button>
        }
      </Container>
    );
  }
}

export default Postcodes;
