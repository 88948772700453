import auth from "../services/authService";

export function AdminPermission(props) {
    const user = auth.getCurrentUser();
    return user && auth.isAdmin() ?
        props.children : null;
}

export function CustomerServicePermission(props) {
    const user = auth.getCurrentUser();
    return user && auth.isCustomerService() ?
        props.children : null;
}
