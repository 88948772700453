import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ReactTable from "react-table";

export default function DuplicatesTable(props) {
    const getColumns = () => {
        return [
            {
                id: "edit", Header: "#", accessor: (d) => "#", filterable: false, sortable: false,
                width: 20,
                Cell: (p) => (
                    <Link to={`/parsedhouses/${props.data[p.index].id}`}>
                        {props.value}
                    </Link>
                ),
            },
            {
                id: "address", Header: "Address", accessor: (d) => d.address, width: 230,
                Cell: (p) => (
                    <a target="_blank" rel="noreferrer" href={props.data[p.index].url}>
                        {p.value}
                    </a>
                ),
            },
            {id: "zipCode", Header: "Postcode", accessor: (d) => d.zipCode, width: 80,},
            {id: "city", Header: "City", accessor: (d) => d.parsedCityName, width: 120,},
            {id: "price", Header: "Price", accessor: (d) => d.price, sortable: false, width: 60,},
            {id: "size", Header: "Size", accessor: (d) => d.size, sortable: false, width: 40,},
            {
                id: "bedrooms",
                Header: "Beds",
                accessor: (d) => d.bedrooms,
                sortable: false,
                width: 50
            },
            {
                id: "furnishing",
                Header: "Furnishing",
                accessor: (d) => d.furnishing,
                sortable: false,
            },
            {id: "type", Header: "Type", accessor: (d) => d.type, sortable: false,},
            {
                id: "source",
                Header: "Source",
                accessor: (d) => d.source,
                filterable: true,
                width: 230,
            },
            {
                id: "duplicateOfId",
                Header: "Duplicate of",
                accessor: (d) => d.duplicateOfId,
                filterable: true,
                width: 230,
                Cell: (p) => (
                    <Link target="blank" to={`/parsedhouses/${p.value}`}>
                        {props.data[p.index].duplicateOfId}
                    </Link>
                ),
            },
            {
                id: "isSharingAllowed",
                Header: "Sharing",
                accessor: (d) => d.isSharingAllowed,
                sortable: false,
                width: 60
            },
            {
                id: "areStudentsAllowed",
                Header: "Students",
                accessor: (d) => d.areStudentsAllowed,
                sortable: false,
                width: 60
            },
            {
                id: "createDate",
                Header: "Created",
                accessor: (d) => d.createDate,
                filterable: true,
                width: 190,
            },
            {
                id: "modifiedDate",
                Header: "Modified",
                accessor: (d) => d.modifiedDate,
                filterable: true,
                width: 190,
            },
            {
                id: "imageUrl",
                Header: "Image",
                accessor: (d) => d.imageUrl,
                filterable: false,
                width: 190,
                Cell: (props) => (
                    <a target="_blank" rel="noreferrer" href={props.value}> {props.value} </a>),
            },
            {
                id: "agentRunId",
                Header: "agentRunId",
                accessor: (d) => d.agentRunId,
                filterable: true,
                width: 300,
            },
        ];
    }

    return <ReactTable data={props.data} columns={getColumns()} defaultPageSize={10}
                       className="-striped -highlight"/>;
}