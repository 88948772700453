import React, {useState}from "react";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Input, Label} from "reactstrap";

export default function SourcesTable(props) {
    const getColumns = () => {
        return [
            {
                id: "edit", Header: "#", accessor: d => "Edit", filterable: false, sortable: false,
                width: 60,
                Cell: p => (
                    <Link to={`/sources/edit/${props.data[p.index].id}`}> {p.value} </Link>
                )
            },
            {
                id: "domain", Header: "Domain", accessor: d => d.domain, filterable: true, sortable: false,
                style: {whiteSpace: "unset"}, Cell: props => <div>{props.value}</div>, width: 230
            },
            {
                id: "baseUrl", Header: "baseUrl", accessor: d => d.baseUrl, width: 600,
                Cell: p=> <Input id={props.data[p.index].id} name="baseUrl" type="text" value={p.value} onChange={props.onUpdate}/>
            },
            {
                id: "isBaseUrlValid", Header: "IsValid", accessor: d => d.isBaseUrlValid.toString(), width: 60,filterable: true,
                Cell: p=> <div className="text-center"><Input id={props.data[p.index].id} name="isBaseUrlValid" type="checkbox" checked={p.value === 'true'} onChange={props.onUpdate}/></div>
            },
            {id: "notes", Header: "Notes", accessor: d => d.notes,
                Cell: p=> <Input id={props.data[p.index].id} name="notes" type="text" value={p.value} onChange={props.onUpdate}/>
            },
            {
                id: "lastParsedHouseFoundDate", Header: "LastParsedHouseFoundDate",
                accessor: d => d.lastParsedHouseFoundDate
            },
            {
                id: "threshold", Header: "Threshold", accessor: d => d.threshold,
                width: 120,
            },
            {
                id: "createDate", Header: "Create date", accessor: d => d.createDate, filterable: false,
                width: 190,
            },
            {
                id: "modifiedDate", Header: "Modified date", accessor: "modifiedDate", filterable: false,
                width: 190
            }
        ];
    };
    return <ReactTable columns={getColumns()} data={props.data}></ReactTable>
}