import React, { Component } from "react";
import { Table, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import http from "../services/httpService";
import DefaultPanelContainer from "./common/defaultPanelContainer";
import LinkButton from "./common/linkButton";
import {AdminPermission} from "./adminPermission";
class SearchProfilesTable extends Component {
    state = { data: [], loading: true };

    async componentDidMount() {
        const { data } = await http.get("searchProfiles/" + this.props.userId);

        for (var i = 0; i < data.length; i++) {
            data[i].dailyAverage = "loading";
        }
        this.setState({ data, loading: false });

        for (var i = 0; i < data.length; i++) {
            const response = await http.get("searchprofiles/dailyaverage/" + data[i].id);
            data[i].dailyAverage = response.data;
        }
        this.setState({ data, loading: false });
    }

    renderCreateSearchProfile = (text, url) => {
        return (
            <Row>
                <Col className="my-3">
                    {
                        <Link to={url} className="btn btn-primary">
                            {text}
                        </Link>
                    }
                </Col>
            </Row>
        );
    };


    render() {
        const { data, loading } = this.state;
        if (loading) return <p>Loading...</p>;
        if (!data || data.length === 0)
            return (
                <LinkButton
                    text="New search profile"
                    url={`/users/${this.props.userId}/searchprofiles/new`}
                />
            );

        return (
            <React.Fragment>
                <DefaultPanelContainer title="Search profiles">
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Size</th>
                                <th>Beds</th>
                                <th>Furnishing</th>
                                <th>City</th>
                                <th>Paid sources</th>
                                <th>Daily Average</th>
                                <th>Exc. Not Sharing</th>
                                <th>Exc. Students</th>
                                <th>Exc. 50+</th>
                                <th>Created</th>
                                <th>Modified</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((s) => (
                                <tr key={s.id}>
                                    <td>
                                        <Link
                                            to={`/users/${this.props.userId}/searchprofiles/${s.id}`}
                                        >
                                            {s.name ? s.name : "No name"}
                                        </Link>
                                    </td>
                                    <td>{s.minPrice}-{s.maxPrice}</td>
                                    <td>{s.size}</td>
                                    <td>{s.bedrooms}</td>
                                    <td>{s.furnishing}</td>
                                    <td>{s.city}</td>
                                    <td>{s.includePaidSources}</td>
                                    <td>{s.dailyAverage}</td>
                                    <td>{s.excludeSharingNotAllowed}</td>
                                    <td>{s.excludeStudentsNotAllowed}</td>
                                    <td>{s.excludeSeniorHousing}</td>
                                    <td>{s.createDate}</td>
                                    <td>{s.modifiedDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </DefaultPanelContainer>

                <AdminPermission>
                <LinkButton
                    text="New search profile"
                    url={`/users/${this.props.userId}/searchprofiles/new`}
                />
                </AdminPermission>
            </React.Fragment>
        );
    }
}

export default SearchProfilesTable;
