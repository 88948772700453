import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const LinkButton = ({ text, url }) => {
  return (
    <Row>
      <Col className="my-3">
        {
          <Link to={url} className="btn btn-primary">
            {text}
          </Link>
        }
      </Col>
    </Row>
  );
};

export default LinkButton;
