import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

class BillingProfile extends Component {
  render() {
    const { billingProfile: data } = this.props;

    // if (loading) return <p>Loading...</p>;
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Card className="my-3">
              <Row>
                <Col>
                  <CardHeader>
                    <strong>BillingProfile</strong>
                  </CardHeader>
                </Col>
              </Row>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      {/* <th>Id</th> */}
                      <th>Autorenew</th>
                      <th>Payment method</th>
                      <th>External CustomerId</th>
                      <th>External SusbscriptionId</th>
                      <th>External PlanId</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* <td>{data.id}</td> */}
                      <td>{data.autorenew === true ? "true" : "false"}</td>
                      <td>{data.paymentMethod}</td>
                      <td>
                        <a
                          href={`${process.env.REACT_APP_STRIPE_CUSTOMERS_URL}${data.externalCustomerId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.externalCustomerId}
                        </a>
                      </td>
                      <td>
                        <a
                          href={`${process.env.REACT_APP_STRIPE_SUBSCRIPTIONS_URL}${data.externalSubscriptionId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.externalSubscriptionId}
                        </a>
                      </td>
                      <td>
                        <a
                          href={`${process.env.REACT_APP_STRIPE_PLANS_URL}${data.externalPlanId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.externalPlanId}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="my-3">
            {/* <Button onClick={this.props.onCancel}>
              Cancel billing profile
            </Button> */}
            <div className="custom-control custom-switch custom-control-inline ml-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="autorenewalsSwitch"
                onChange={this.props.onToggleAutorenewals}
                checked={data.autorenew || false}
              />
              <label
                className="custom-control-label"
                htmlFor="autorenewalsSwitch"
              >
                autorenewals
              </label>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default BillingProfile;
