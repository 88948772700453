import http from "../services/httpService";

async function fetchTableData(state, url) {
  // console.log(state.pageSize, state.page, state.sorted, state.filtered);
  const { pageSize, page, sorted, filtered } = state;
  const { data } = await http.post(url, {
    pageSize,
    page,
    sorted,
    filtered
  });

  return data;
}

export default {
  fetchTableData
};
