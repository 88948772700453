import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import http from '../../services/httpService'
import { Link } from 'react-router-dom'

class UserParsedHouseEventsTable extends Component {
  state = { data: [], pages: null, loading: true }
  columns = [
    {
      id: 'edit', Header: '#', accessor: d => 'Edit', filterable: false, sortable: false,
      width: 40,
      Cell: props => (
        <Link to={`/parsedhouses/${this.state.data[props.index].parsedHouseId}`}>
          {props.value}
        </Link>
      )
    },
    {
      id: 'address', Header: 'Address', accessor: d => d.address, width: 230,
      Cell: props => (
        <a target="_blank" rel="noreferrer" href={this.state.data[props.index].url}>
          {props.value}
        </a>
      )
    },
    { id: 'zipCode', Header: 'Postcode', accessor: d => d.zipCode, width: 80 },
    { id: 'city', Header: 'City', accessor: d => d.city, width: 100 },
    { id: 'price', Header: 'Price', accessor: d => d.price, sortable: false, width: 50 },
    { id: 'size', Header: 'Size', accessor: d => d.size, sortable: false, width: 40 },
    { id: 'bedrooms', Header: 'Beds', accessor: d => d.bedrooms, sortable: false, width: 40 },
    { id: 'furnishing', Header: 'Furnishing', accessor: d => d.furnishing, sortable: false },
    { id: 'source', Header: 'Source', accessor: d => d.source, filterable: true, width: 200 },
    {
      id: 'duplicateOfId', Header: 'Duplicate', accessor: d => d.duplicateOfId, filterable: true, width: 200,
      Cell: props => (
        <Link to={`/parsedhouses/${props.value}`}>
          {props.value}
        </Link>
      )
    },
    { id: 'createDate', Header: 'Created', accessor: d => d.createDate, filterable: true, width: 190 },
    { id: 'modifiedDate', Header: 'Modified', accessor: d => d.modifiedDate, filterable: true, width: 190 },
    {
      id: 'diagnostics', Header: 'Diagnostics', filterable: true, width: 200,
      Cell: props => (
        <Link
          to={`/diagnostics?userId=${this.props.userId}&parsedHouseId=${this.state.data[props.index].parsedHouseId}`}>
          Run Diagnostics
        </Link>
      )
    },
  ]

  fetchData = async (state, instance) => {
    this.setState({ loading: true })

    const { pageSize, page, sorted, filtered } = state
    const url = this.props.getUrl()
    const { data } = await http.post(url, { pageSize, page, sorted, filtered })

    this.setState({
      data: data.rows,
      pages: data.pages,
      loading: false
    })
  }

  render () {
    const { data, loading, pages } = this.state
    const columns = this.columns
    return (
      <ReactTable manual className="my-4" data={data} pages={pages} loading={loading} columns={columns}
                  filterable onFetchData={this.fetchData}
      />
    )
  }
}

export default UserParsedHouseEventsTable
