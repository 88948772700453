import React from "react";
import BaseForm from "./../common/baseForm";
import { Row, Col, Form, Container } from "reactstrap";
import Joi from "joi-browser";
import { CitiesClient } from "../../utils/api.js";
import { toast } from "react-toastify";

class CityForm extends BaseForm {
  state = {
    data: {
      displayName: "",
      name: "",
      searchTermsCsv: "",
      disabled: true,
    },
    errors: {},
  };
  client = new CitiesClient();
  schema = {
    name: Joi.required(),
    displayName: Joi.required(),
    searchTermsCsv: Joi.required(),
    disabled: Joi.boolean(),
  };

  doSubmit = async () => {
    this.client
      .post(this.state.data)
      .then(() => toast.success("New city created"))
      .catch((err) => toast.error("Error creating city: " + err));
  };
  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>{this.renderInput("name", "Name")}</Col>
          </Row>
          <Row>
            <Col>{this.renderInput("displayName", "Display Name")}</Col>
          </Row>
          <Row>
            <Col>
              {this.renderInput(
                "searchTermsCsv",
                "Search Terms (comma separated list)"
              )}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderButton("Create")}</Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default CityForm;
