import React, {useEffect, useState} from "react";
import { Container, Row, Col} from "reactstrap";
import {SingleStat} from "./singleStat";
import {getNewCustomersStat, formatDate} from "../../services/dashboardService";
import {DateRangeSearch} from "../common/dateRange";

export function CustomerServiceDashboard() {
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const [stats, setStats] = useState({});

    useEffect(() => {
        loadStats();
    }, []);

    const loadStats = async () => {
        const newCustomersStat =  await getNewCustomersStat(startDate, endDate);
        setStats(newCustomersStat);
    };

    const handleDateChange = (e) => {
        if(e.target.id === "startDate")
            setStartDate(e.target.value);
        else
            setEndDate(e.target.value);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <DateRangeSearch startDate={startDate} endDate={endDate} onDateChange={handleDateChange} 
                    onSearch={loadStats}/>
                </Col>
            </Row>
            {Object.keys(stats).map((key, i) => 
                <Row key={i}>
                    <Col>
                        <SingleStat name={key} value={stats[key]} />
                    </Col>
                </Row>)}
        </Container>
    );
}

export default CustomerServiceDashboard;