import React, { useEffect, useState } from 'react'
import SourcesTable from './sourcesTable'
import httpService from '../../services/httpService'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function Sources () {
  const [sources, setSources] = useState([])
  const [changes, setChanges] = useState([])
  useEffect(() => {
    httpService.get('sources').then(response => {
      const data = response.data
      data.map(s => s.notes === null ? s.notes = '' : s.notes)
      setSources(data)
    })
  }, [])
  const handleUpdate = (e) => {
    sources.map(node => {
        if (node.id === e.target.id) {
          if (e.target.type === 'checkbox') {
            node[e.target.name] = e.target.checked
          } else {
            node[e.target.name] = e.target.value
          }
          setChanges([...changes, node])
        }
        return node
      }
    )
    setSources([...sources])
  }
  const handleClick = (e) => {
    changes.map(node => {
        httpService.put(`sources/${node.id}`, node).then(response => {
          if (response.status === 204) {
            setChanges(changes.filter(change => change.id !== node.id))
          } else {
            toast.error('Changes not saved: ' + response.status)
          }
        })
        return node
      }
    )

    toast.success('Sources updated successfully')
  }
  return <>
    <Button className="m-3" onClick={handleClick}>Save</Button>
    <Link to="/sources/analyze">Analyze</Link>
    <SourcesTable data={sources} onUpdate={handleUpdate}></SourcesTable>
    <Button className="m-3" onClick={handleClick}>Save</Button>
  </>
}