import React from "react";
import {Button} from "reactstrap";

export function DateRangeSearch(props) {
    return (<div>
        <DateRange className="my-1" startDate={props.startDate} endDate={props.endDate}
                   onDateChange={props.onDateChange}/>&nbsp;
        <Button className="my-1" onClick={props.onSearch}>Search</Button>
    </div>)
}

export function DateRange(props) {
    return (
        <React.Fragment>
            <input type="date" id="startDate" onChange={props.onDateChange}
                   value={props.startDate}/>&nbsp;
            <input type="date" id="endDate" onChange={props.onDateChange} value={props.endDate}/>
        </React.Fragment>)
}