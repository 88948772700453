import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import entityTableService from "../services/entityTableService";
import { Link } from "react-router-dom";

class EntityTable extends Component {
  state = {
    data: [],
    pages: null,
    loading: true,
  };

  fetchData = async (state, instance) => {
    var self = this;
    var delayInMilliseconds = 1500; //1 second
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(async function() {
      await self.doFetchData(self, state);
    }, delayInMilliseconds);
  };

  doFetchData = async (self, state) => {
    self.setState({ loading: true });

    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    const data = await entityTableService.fetchTableData(state, this.props.url);

    self.setState({
      data: data.rows,
      pages: data.pages,
      loading: false,
    });
  };

  render() {
    const { data, loading, pages } = this.state;
    const columns = this.props.columns;
    columns[this.props.linkToEditIndex].Cell = (props) => (
      <Link to={`/${this.props.url}/${this.state.data[props.index].id}`}>
        {props.value}
      </Link>
    );
    return (
      <ReactTable
        manual
        className="my-4"
        data={data}
        pages={pages}
        loading={loading}
        columns={columns}
        filterable
        onFetchData={this.fetchData}
        minRows={this.props.minRows}
      />
    );
  }
}

export default EntityTable;
