import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import httpService from '../../services/httpService'
import DefaultPanelContainer from '../common/defaultPanelContainer'

export default function Diagnostics (props) {
  const [sources, setSources] = useState(null)

  useEffect(() => {
    httpService.get('diagnostics' + props.location.search).then(response => {
      setSources(response.data)
    })
  }, [props.location.search])
  const queryParams = new URLSearchParams(props.location.search)
  const userId = queryParams.get('userId')
  const parsedHouseId = queryParams.get('parsedHouseId')

  return (
    <>
      <div>User ID: <a href={`/users/${userId}`}>{userId}</a></div>
      <div>Parsed House ID: <a href={`/parsedhouses/${parsedHouseId}`}>{parsedHouseId}</a></div>
      {sources && sources.map(source =>
        <DefaultPanelContainer title={source.searchProfileName}>
          <DiagnosticsTable data={source.diagnostics}></DiagnosticsTable>
        </DefaultPanelContainer>
      )}
    </>
  )
}

function DiagnosticsTable (props) {

  const getColumns = () => {
    return [
      {
        id: 'searchCriteria',
        Header: 'searchCriteria',
        accessor: d => d.searchCriteria,
        filterable: true,
        sortable: false,
        style: { whiteSpace: 'unset' },
        Cell: props => <div>{props.value}</div>,
        width: 300
      },
      {
        id: 'searchProfileValue', Header: 'searchProfileValue', accessor: d => d.searchProfileValue, width: 300,
      },
      {
        id: 'parsedHouseValue',
        Header: 'parsedHouseValue',
        accessor: d => d.parsedHouseValue,
        width: 300,
        filterable: true,
      },
      {
        id: 'isMatch', Header: 'isMatch', accessor: d => d.isMatch.toString(),
      },
    ]
  }
  return <ReactTable columns={getColumns()} data={props.data}></ReactTable>
}
