import React, { Component } from "react";
import EntityTable from "./entityTable";
import { Link } from "react-router-dom";

class TestimonialsTable extends Component {
  state = { data: {}, url: "testimonials" };

  getColumns = () => {
    return [
      {
        id: "edit",
        Header: "#",
        accessor: d => "Edit",
        filterable: false,
        sortable: false,
        width: 60
      },
      {
        id: "fullName",
        Header: "FullName",
        accessor: d => d.fullName,
        width: 150
        // Cell: props => (
        //   <Link to={`/users/${this.state.data[props.index].id}`}>
        //     {props.value}
        //   </Link>
        // )
      },
      {
        id: "email",
        Header: "Email",
        accessor: d => d.email,
        sortable: false,
        cell: props => (
          <Link to={`/users/${this.state.data[props.index].id}`}>
            {props.value}
          </Link>
        )
      },
      {
        id: "picturePath",
        Header: "PicturePath",
        accessor: d => d.picturePath,
        filterable: false,
        width: 150,
        Cell: props => (
          <a href={props.value}>
            <img
              className="img img-thumbnail"
              src={props.value}
              target="_blank"
            />
          </a>
        )
      },
      {
        id: "message",
        Header: "Message",
        accessor: d => d.message,
        filterable: false,
        sortable: false,
        style: { "white-space": "unset" },
        Cell: props => <div>{props.value}</div>
      },
      {
        id: "createDate",
        Header: "Create date",
        accessor: d => d.createDate,
        filterable: false,
        width: 190
      },
      {
        id: "modifiedDate",
        Header: "Modified date",
        accessor: "modifiedDate",
        filterable: false,
        width: 190
      }
    ];
  };

  render() {
    return (
      <EntityTable
        url="testimonials"
        columns={this.getColumns()}
        linkToEditIndex="0"
      />
    );
  }
}

export default TestimonialsTable;
