import React, { useState } from 'react'
import { Button, Col, Container, Form, Input, Label, Row } from 'reactstrap'
import httpService from '../../services/httpService'
import DefaultPanelContainer from '../common/defaultPanelContainer'
import AnalysisResult from './analysisResult'
import { testDomain } from '../../services/urlTestService'

const AnalyzeSource = () => {
  const [url, setUrl] = useState('')
  const [result, setResult] = useState(null)
  const [parserDefinitionName, setParserDefinitionName] = useState('goesenroos')
  const [startUrls, setStartUrls] = useState([])
  const handleSubmit = (event) => {
    event.preventDefault()
    httpService.post('sources/analyze', { url: url })
      .then(response => {
        setResult(response.data)
        const parserDefinitionName = response.data.parserDefinitionName
        if (parserDefinitionName) {
          setParserDefinitionName(parserDefinitionName)
        }
      })
  }

  const changeUrl = (e) => {
    setUrl(e.target.value)
    setResult(null)
  }

  const testUrlClick = async (e) => {
    const result = await testDomain(url, parserDefinitionName)
    e.preventDefault()
    alert(JSON.stringify(result, null, 2))
  }

  const addToAgent = async (e) => {
    const result = await httpService.post('parserDefinitions/start-urls', {
      parserDefinitionName: parserDefinitionName,
      startUrl: url
    })
    if (result.status === 200) {
      alert('Url added to agent')
    }

  }
  const getUrls = async (e) => {
    const result = await httpService.get(`parserDefinitions/start-urls?parserDefinitionName=${parserDefinitionName}`)
    console.log(result)
    setStartUrls(result.data)
  }

  const removeUrl = async (index) => {
    if (window.confirm('Are you sure you want to remove this URL?')) {
      const newStartUrls = [...startUrls]
      const result = await httpService.delete('parserDefinitions/start-urls', {
        data: { parserDefinitionName: parserDefinitionName, startUrl: newStartUrls[index] },
        headers: { 'Content-Type': 'application/json' }
      })
      if (result.status === 200) {
        newStartUrls.splice(index, 1)
        setStartUrls(newStartUrls)
      }
    }
  }

  return (
    <Container>
      <DefaultPanelContainer title="Analyze source">
        <Row>
          <Col sm="8">
            <Form onSubmit={handleSubmit}>
              <div className="form-group">
                <Label style={{ width: '100%' }}>
                  Url:
                  <Input type="text" value={url} onChange={changeUrl} style={{ width: '100%' }}/>
                </Label>
              </div>
              <Button type="submit">Analyze</Button>
            </Form>
            <div className="form-group">
              <Label>
                Parser Definition Name:
                <select value={parserDefinitionName} onChange={(e) => setParserDefinitionName(e.target.value)}>
                  <option value="goesenroos">Goesenroos</option>
                  <option value="realworks">Realworks</option>
                  <option value="ogonline">Ogonline</option>
                  <option value="sysproperties">Sysproperties</option>
                  <option value="eazlee">Eazlee</option>
                  <option value="eazleeapproval">Eazlee approval</option>
                </select>
              </Label>
              <button className="btn btn-secondary ml-2" onClick={testUrlClick}> Fetch from Agent</button>
              <button className="btn btn-secondary ml-2" onClick={addToAgent}> Add to Agent</button>
            </div>
          </Col>
          <Col>
            <AnalysisResult result={result}/>
          </Col>
        </Row>
      </DefaultPanelContainer>
      <DefaultPanelContainer title="Start urls">
        <Row>
          <Col>
            <button className="btn btn-secondary ml-2" onClick={getUrls}>Get urls</button>
            <table className="table">
              <thead>
              <tr>
                <th>URL</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {startUrls.map((url, index) => (
                <tr key={index}>
                  <td>{url}</td>
                  <td>
                    <button className="btn btn-secondary" onClick={() => removeUrl(index)}>Remove</button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </DefaultPanelContainer>
    </Container>
  )

}

export default AnalyzeSource