import React, { Component } from "react";
import { Label, Input } from "reactstrap";

class TreeList extends Component {
  handleTreeChange = ({ currentTarget: input }) => {
    const id = input.value;
    const { zipCodesTree: tree, searchZipCodeIds, onTreeChange } = this.props;
    let newSearchZipCodeIds = [];
    searchZipCodeIds.forEach(e => newSearchZipCodeIds.push(e));
    const pos = newSearchZipCodeIds.indexOf(id);
    const treeNode = this.findTreeNodeById(id, tree[0]);
    if (pos !== -1) {
      newSearchZipCodeIds = this.deleteDescendantsIds(
        treeNode,
        newSearchZipCodeIds
      );
    } else {
      const treeNode = this.findTreeNodeById(id, tree[0]);
      this.addDescendantsIds(treeNode, newSearchZipCodeIds);
    }
    newSearchZipCodeIds = this.selectAscendants(treeNode, newSearchZipCodeIds);
    onTreeChange(newSearchZipCodeIds);
  };

  addDescendantsIds = (node, list) => {
    list.push(node.value);
    node.children.forEach(element => {
      this.addDescendantsIds(element, list);
    });
  };

  deleteDescendantsIds = (node, list) => {
    list = list.filter(e => e !== node.value);

    node.children.forEach(element => {
      list = this.deleteDescendantsIds(element, list);
    });
    return list;
  };

  selectAscendants = (node, list) => {
    if (node.parent === null) return list;

    let parent = node.parent;
    while (parent !== null) {
      let allChildrenselected = list.includes(parent.children[0].value);
      for (let index = 1; index < parent.children.length; index++) {
        const element = parent.children[index];
        allChildrenselected =
          list.includes(element.value) && allChildrenselected;
      }
      if (allChildrenselected) {
        list.push(parent.value);
      } else {
        // console.log("remove " + parent.name);
        list = list.filter(e => e !== parent.value);
      }
      parent = parent.parent;
    }
    return list;
  };

  findTreeNodeById = (id, treeNode) => {
    if (treeNode.value === id) {
      return treeNode;
    } else
      for (let index = 0; index < treeNode.children.length; index++) {
        const element = treeNode.children[index];

        const result = this.findTreeNodeById(id, element);
        if (result && result.value === id) return result;
      }
    return null;
  };

  renderTree = node => {
    if (node.children && node.children.length === 0) {
      return <li key={node.name}>{this.renderTreeCheckbox(node)}</li>;
    }

    return (
      <li key={node.name}>
        {this.renderTreeCheckbox(node)}
        <ul>
          {node.children.map(child => {
            child.parent = node;
            return this.renderTree(child);
          })}
        </ul>
      </li>
    );
  };

  renderTreeCheckbox = node => (
    <React.Fragment>
      <Label check>
        <Input
          type="checkbox"
          name={node.value}
          id={node.value}
          label={node.name}
          value={node.value}
          checked={this.props.searchZipCodeIds.indexOf(node.value) > -1}
          onChange={this.handleTreeChange}
        />{" "}
        {node.name}
      </Label>
    </React.Fragment>
  );

  render() {
    const { zipCodesTree } = this.props;

    return (
      <ul>{zipCodesTree.length > 0 && this.renderTree(zipCodesTree[0])}</ul>
    );
  }
}

export default TreeList;
