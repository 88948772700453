import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiUrl = "authenticate";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(email, password) {
  const { data: jwt } = await http.post(apiUrl, null, {
    auth: {
      username: email,
      password
    }
  });
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function isAdmin() {
  const user = getCurrentUser();
  return user.role === "Administrator" ||
      (Array.isArray(user.role) && user.role.find(r => r === "Administrator") !== undefined);
}

export function isCustomerService() {
  const user = getCurrentUser();
  return user.role === "CustomerService" ||
      (Array.isArray(user.role) && user.role.find(r => r === "CustomerService") !== undefined);
}
export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  logout,
  getCurrentUser,
  getJwt,
  isAdmin,
  isCustomerService
};
