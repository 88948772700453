import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";

import AgentStatus from "./agentStatus";
import ParserDefinitionsTable from "./parserDefinitionsTable";
import ParserDefinitionCreateForm from "./parserDefinitionCreateForm";
import httpService from "../../services/httpService";

export default function Parsers() {
    const [parserDefinitions, setParserDefinitions] = useState([]);
    useEffect(() => {
        httpService.get("parserdefinitions").then(response => setParserDefinitions(response.data));
    }, []);

    return (
        <Container>
            <Row>
                <Col md>
                    <AgentStatus/>
                </Col>
                <Col md>
                    <ParserDefinitionCreateForm/>
                </Col>
            </Row>
            <ParserDefinitionsTable data={parserDefinitions}/>
        </Container>
    );
}

