import React, { Component } from "react";
import Joi from "joi-browser";
import { Label, Input } from "reactstrap";
import BaseInput from "./baseInput";
import BaseSelectInput from "./baseSelectInput";
import BaseInputDate from "./baseInputDate";
class BaseForm extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    const options = {
      abortEarly: false
    };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;
    console.log("​BaseForm -> validate -> errors", errors);
    console.log(errors);
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  checkErrors = input => {
    // console.log("​input", input);
    if(input.disabled)
      return
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    return errors;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = this.checkErrors(input);
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleCheckboxChange = ({ currentTarget: input }) => {
    const errors = this.checkErrors(input);
    const data = { ...this.state.data };
    data[input.name] = !data[input.name];
    this.setState({ data, errors });
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderInput(name, label, type = "text", disabled = false) {
    const { data, errors } = this.state;

    return (
      <BaseInput
        type={type}
        name={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
      />
    );
  }

  handleDateChange = (date, name) => {
    const currentTarget = { currentTarget: { name, value: date } };
    this.handleChange(currentTarget);
  };

  renderInputDate(name, label) {
    const { data, errors } = this.state;

    return (
      <BaseInputDate
        name={name}
        selected={data[name] === null ? "" : data[name]}
        label={label}
        onChange={date => this.handleDateChange(date, name)}
        error={errors[name]}
        className="form-control"
        dateFormat="dd/MM/yyyy"
      />
    );
  }

  renderCheckbox(name, label, disabled = false) {
    const { data, errors } = this.state;

    return (
      <React.Fragment>
        <Label check>
          <Input
            type="checkbox"
            name={name}
            id={name}
            label={label}
            value={data[name]}
            checked={data[name]}
            onChange={this.handleCheckboxChange}
            disabled={disabled}
          />{" "}
          {label}
        </Label>
        {errors[name] && (
          <div className="alert alert-danger">{errors[name]}</div>
        )}
      </React.Fragment>
    );
  }

  renderSelect(name, label, options, onChange = this.handleChange) {
    const { data, errors } = this.state;
    return (
      <BaseSelectInput
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={onChange}
        errors={errors[name]}
      />
    );
  }
}

export default BaseForm;
