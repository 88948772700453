import React, {useEffect, useState} from "react";
import http from "../services/httpService";
import {formatDate} from "../services/dashboardService";
import {Button, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {DateRange} from "./common/dateRange";

export default function Stats(props) {
    const [stats, setStats] = useState({});
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const [city, setCity] = useState("amsterdam");
    const [minPrice, setMinPrice] = useState(750);
    const [maxPrice, setMaxPrice] = useState(1200);
    const [minBedrooms, setMinBedrooms] = useState(0);
    const [maxBedrooms, setMaxBedrooms] = useState(30);
    const [metric, setMetric] = useState("avgcityneighbourhood");

    useEffect(() => {
        loadStats();
    }, []);

    const loadStats = async () => {
        let search = `${metric}?start=${startDate}&end=${endDate}&minPrice=${minPrice}
      &maxPrice=${maxPrice}&cityName=${city}&minBedrooms=${minBedrooms}&maxBedrooms=${maxBedrooms}`;
        const {data: stats} = await http.get(props.url + search);
        setStats(stats);
    }

    const handleClick = () => {
        navigator.clipboard.writeText("<empty clipboard>");
    }

    const handleDateChange = (e) => {
        if (e.target.id === "startDate")
            setStartDate(e.target.value);
        else
            setEndDate(e.target.value);
    }

    return <Container>
        <Row className="my-4">
            <Col>
                <h2>Average price per square meter by neighbourhood</h2>
            </Col>
        </Row>
        <Row className="my-4">
            <Col>
                <Label for="metric">Metric</Label>
                <div>
                    <select
                        onChange={event => setMetric(event.target.value)}
                        style={{width: "100%"}} value={metric}
                    >
                        <option value="avgcityneighbourhood">Average price/sqm</option>
                        <option value="countperbedrooms">Count</option>
                    </select>
                </div>
            </Col>
            <Col>
                <Label for="dateRange">Date Range</Label>
                <div>
                    <DateRange startDate={startDate} endDate={endDate}
                               onDateChange={handleDateChange}/>
                </div>
            </Col>
            <Col>
                <FormGroup row>
                    <Col>
                        <Label for="minPrice">Min Price</Label>
                        <Input value={minPrice}
                               onChange={(el) => setMinPrice(el.target.value)}></Input>
                    </Col>
                    <Col>
                        <Label for="maxPrice">Max Price</Label>
                        <Input value={maxPrice}
                               onChange={(el) => setMaxPrice(el.target.value)}></Input>
                    </Col>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup row>
                    <Col>
                        <Label for="minBedrooms">Min Bedrooms</Label>
                        <Input value={minBedrooms}
                               onChange={(el) => setMinBedrooms(el.target.value)}></Input>
                    </Col>
                    <Col>
                        <Label for="maxBedrooms">Max Bedrooms</Label>
                        <Input value={maxBedrooms}
                               onChange={(el) => setMaxBedrooms(el.target.value)}></Input>
                    </Col>
                </FormGroup>
            </Col>
            <Col>
                <Label for="city">City</Label>
                <Input id="city" className="form-control" type="text" value={city}
                       onChange={(el) => setCity(el.target.value)}></Input>
            </Col>
        </Row>
        <Row>
            <Col>
                <Button className="mb-4" onClick={loadStats}>Search</Button>
            </Col>
        </Row>
        <Row>
            <Col>
     <pre onClick={handleClick}>{JSON.stringify(stats, null, 2)
         .replaceAll("\"", "")
         .replaceAll(",", "")
         .replaceAll(" :", ":")
         .replaceAll(": ", ":")}</pre>
            </Col>
        </Row>
    </Container>;
}