import React, {useState} from "react";
import {Button} from "reactstrap";
import httpService from "../../services/httpService";

export default function AddSource(props) {
    const [source, setSource] = useState([]);
    const [isBaseUrlValid, setIsBaseUrlValid] = useState(false);
    return <form onSubmit={(e) => {
        e.preventDefault();
        httpService.post('api/sources', {domain: "domain", baseUrl: "baseUrl"})
    }}>
        <label htmlFor="domain">
            Domain
            <input id="domain" type="text"
                   onChange={(e) => source.domain = e.target.value}/>
        </label>
        <label htmlFor="baseUrl">
            Base url
            <input id="baseUrl" type="text"/>
        </label>
        <label htmlFor="isBaseUrlValid">
            Is Valid
            <input id="isBaseUrlValid" type="checkbox" checked={isBaseUrlValid}
                   onChange={(e) => setIsBaseUrlValid(e.target.checked)}/>
        </label>
        <Button type="submit">Submit</Button>

    </form>
}
