import React, { Component } from "react";
import http from "../services/httpService";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Container, Button } from "reactstrap";
import { toast } from "react-toastify";
class FurnishingCriteria extends Component {
  state = { data: [], changed: new Set([]) };

  async componentDidMount() {
    const { data } = await http.get("furnishing");
    // console.log(data);
    this.setState({ data: data });
  }

  handleClick = props => {
    // console.log("clicked");
    // console.log(props);
    if (this.state.changed.size > 0) {
      this.state.changed.forEach(element => {
        http.post("furnishing", element);
      });
      toast.success("furnishing criteria saved");
      this.setState({ changed: new Set([]) });
    }
  };

  //https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-editable-content
  renderEditable = cellInfo => {
    return (
      <div
        style={{ backgroundColor: "#fafafa", whiteSpace: "normal" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const { data, changed } = this.state;
          if (data[cellInfo.index][cellInfo.column.id] !== e.target.innerHTML) {
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            changed.add(data[cellInfo.index]);
            this.setState({ data, changed });
          }
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.data[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  };

  getColumns = () => {
    return [
      {
        id: "name",
        Header: "name",
        accessor: d => d.name,
        width: 360,
        Cell: this.renderEditable
      },
      {
        id: "synonymsCsv",
        Header: "synonymsCsv",
        accessor: d => d.synonymsCsv,
        sortable: false,
        Cell: this.renderEditable
      },
      {
        id: "order",
        Header: "Order",
        accessor: d => d.order,
        filterable: false,
        width: 90
      }
    ];
  };

  render() {
    const { loading, data } = this.state;
    return (
      <Container>
        <ReactTable
          manual
          className="my-4"
          data={data}
          loading={loading}
          columns={this.getColumns()}
          onFetchData={this.fetchData}
          minRows="3"
        />
        {
          <Button
            onClick={this.handleClick}
            disabled={this.state.changed.size === 0}
            className="btn btn-primary"
          >
            Save
          </Button>
        }
      </Container>
    );
  }
}

export default FurnishingCriteria;
