import React from "react";

const FileUpload = props => {
  return (
    <input
      type="file"
      title="Add pictures"
      multiple
      onChange={props.onChange}
    />
  );
};

export default FileUpload;
