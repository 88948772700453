import React from "react";
import {Col, Container, Form, Row} from "reactstrap";
import BaseForm from "../common/baseForm";
import http from "../../services/httpService";
import Joi from "joi-browser";

class parserDefinitionCreateForm extends BaseForm {
    state = {
        data: {
            name: "",
            spiderId: ""
        },
        errors: {},
        states: []
    };

    schema = {
        name: Joi.string(),
        spiderId: Joi.string()
            .required()
            .label("spider ID"),
    };

    doSubmit = async () => {
        try {
            const {data} = this.state;
            const {data: id} = await http.post("parserdefinitions/create", data);
            window.location = "parserdefinitions/" + id;
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                errors.username = ex.response.data;
                this.setState({errors});
            }
        }
    };

    render() {
        return (
            <Container>
                <Form onSubmit={this.handleSubmit} className="mt-4">
                    <Row>
                        <Col>{this.renderInput("name", "Name")}</Col>
                        <Col>{this.renderInput("spiderId", "Spider Id")}</Col>
                    </Row>
                    <Row>
                        <Col className="my-1">
                            {this.renderButton("Create parser definition")}
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }
}

export default parserDefinitionCreateForm;
