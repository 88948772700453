import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

const DefaultPanelContainer = props => {
  return (
    <Row>
      <Col>
        <Card className="my-3">
          <Row>
            <Col>
              <CardHeader>
                <strong>{props.title}</strong>
              </CardHeader>
            </Col>
          </Row>
          <CardBody>{props.children}</CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DefaultPanelContainer;
