import React, { Component } from "react";

import { Link } from "react-router-dom";
import entityTableService from "../services/entityTableService";
import ReactTable from "react-table";
import "react-table/react-table.css";

class PaymentsTable extends Component {
  state = {
    data: [],
    pages: null,
    loading: true
  };

  fetchData = async (state, instance) => {
    this.setState({ loading: true });

    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    const data = await entityTableService.fetchTableData(
      state,
      this.props.endpoint
    );

    this.setState({
      data: data.rows,
      pages: data.pages,
      loading: false
    });
  };

  columns = [
    {
      id: "edit", Header: "#", accessor: d => "Edit", filterable: false, sortable: false, width: 60,
      Cell: props => (
        <Link to={`/payments/${this.state.data[props.index].id}`}>
          {props.value}
        </Link>
      )
    },
    { id: "fullName", Header: "FullName", accessor: d => d.fullName },
    { id: "email", Header: "Email", accessor: d => d.email, sortable: false,
      Cell: (props) => (
          <Link to={`/users/${this.state.data[props.index].userId}`}> {props.value} </Link>
      ),
    },
    { id: "subscriptionName", Header: "Subscription", accessor: d => d.subscriptionName, filterable: false, width: 90 },
    {
      id: "isPaid", Header: "IsPaid", accessor: d => d.isPaid.toString(), width: 70, sortable: false,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      )
    },
    { id: "paymentDate", Header: "PaymentDate", accessor: d => d.paymentDate, width: 190, filterable: false },
    { id: "price", Header: "price", accessor: d => d.price, width: 80, filterable: false, sortable: false },
    { id: "paymentMethod", Header: "Payment method", accessor: d => d.paymentMethod, width: 100, filterable: true, sortable: false },
    { id: "refundDate", Header: "RefundDate", accessor: d => d.refundDate, width: 190, filterable: false, sortable: false },
    { id: "createDate", Header: "Create date", accessor: d => d.createDate, filterable: false, width: 190 },
    { id: "modifiedDate", Header: "Modified date", accessor: "modifiedDate", filterable: false, width: 190 }
  ];

  render() {
    const { data, loading, pages } = this.state;
    return (
      <ReactTable
        manual
        className="my-4"
        data={data}
        pages={pages}
        loading={loading}
        columns={this.columns}
        filterable
        onFetchData={this.fetchData}
        minRows={this.props.minRows}
      />
    );
  }
}

export default PaymentsTable;
