import React, { Component } from "react";
import { Card, CardBody, Button, Badge } from "reactstrap";
class HouseImageListItem extends Component {
  render() {
    return (
      <Card
        className={
          this.props.image.isFeatured ? "border border-success mb-2" : "mb-2"
        }
      >
        <img
          className="card-img-top"
          width="300px"
          height="300px"
          src={this.props.image.path}
          onClick={() => this.props.onSelect(this.props.image.id)}
        />
        <CardBody>
          <Button
            onClick={() => this.props.onDelete(this.props.image.id)}
            className="btn btn-secondary"
          >
            Delete image
          </Button>
          {this.props.image.isFeatured && (
            <Badge className="float-right" color="success">
              Featured
            </Badge>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default HouseImageListItem;
