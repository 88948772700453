import React from "react";
import { Container, Row, Col } from "reactstrap";

const DefaultTableContainer = props => {
  return (
    <Container fluid>
      <Row>
        <Col sm={{ size: 12}}>{props.children}</Col>
      </Row>
    </Container>
  );
};

export default DefaultTableContainer;
