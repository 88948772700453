import React from "react";
import { Container, Form, Row, Col, Button } from "reactstrap";
import BaseForm from "../common/baseForm";
import http from "../../services/httpService";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import FileUpload from "../common/FileUpload";
import HouseImagesList from "./houseImagesList";
import CandidatesTable from "./candidatesTable";
import { getCurrentUser } from "../../services/authService";
import BaseEditor from "../common/baseEditor";
import { Link } from "react-router-dom";

class HouseForm extends BaseForm {
  user = getCurrentUser();
  state = {
    data: {
      userId: this.user.id,
      title: "",
      address: "",
      bedrooms: "",
      cityId: "",
      price: "",
      postcode: "",
      furnishing: "",
      size: "",
      description: "",
      houseState: "0",
    },
    imgCollection: "",
    images: null,
    candidates: null,
    cities: [],

    errors: {},
    houseStates: [],
  };

  schema = {
    id: Joi.string(),
    userId: Joi.string(),
    title: Joi.string(),
    address: Joi.string()
      .required()
      .label("address"),
    bedrooms: Joi.required(),
    cityId: Joi.required(),
    furnishing: Joi.required(),
    postcode: Joi.optional(),
    description: Joi.required(),
    price: Joi.required(),
    houseState: Joi.required(),
    cityName: Joi.optional(),
    size: Joi.required(),
    createDate: Joi.string().optional(),
    modifiedDate: Joi.string().optional(),
  };

  findCandidatesAsync = async (data) => {
    const { price, size, bedrooms, postcode, furnishing, cityId } = data;
    const { data: candidates } = await http.post("candidates", {
      price: price,
      size: size,
      bedrooms: bedrooms,
      postcode: postcode,
      cityId: cityId,
      furnishing: furnishing,
    });
    return candidates;
  };

  async componentDidMount() {
    const { data: cities } = await http.get("cities/selectlist");
    const { data: houseStates } = await http.get("houses/housestates");

    const searchProfileId = this.props.match.params.id;
    if (searchProfileId !== "new") {
      const { data } = await http.get(
        "houses/edit/" + this.props.match.params.id
      );

      this.setState({
        data,
        cities,
        houseStates,
        prevState: data.houseState.toString(),
      });
      const { data: images } = await http.get(
        "houses/getimages/" + this.props.match.params.id
      );

      this.setState({
        data,
        images: images,
      });

      const candidates = await this.findCandidatesAsync(data);

      this.setState({
        isLoaded: true,
        candidates,
      });
    } else {
      const { data } = this.state;
      data.cityId = cities[0].value;
      // data.userId = this.user.id.toString();

      this.setState({
        cities,
        data,
        houseStates,
        prevState: houseStates[0].value,
      });
    }
  }

  submitImages = async (houseId) => {
    var formData = new FormData();
    for (const key of Object.keys(this.state.imgCollection)) {
      formData.append("imgCollection", this.state.imgCollection[key]);
    }

    await http.post("houses/upload/" + houseId, formData);
  };

  stateChangedToActive = () => {
    return this.state.prevState !== "1" && this.state.data.houseState === "1"; ///Active state is 1
  };

  doSubmit = async () => {
    try {
      if (
        this.stateChangedToActive() &&
        !window.confirm(
          "This listing will be sent to all candidates. Are you sure?"
        )
      ) {
        return;
      }

      const editHouseId = this.props.match.params.id;
      if (editHouseId === "new") {
        const { data } = this.state;
        const { data: houseId } = await http.post("houses/add", data);
        await this.submitImages(houseId);

        window.location = "/houses/" + houseId;
      } else {
        await http.post("houses/edit", this.state.data);

        await this.submitImages(editHouseId);
      }
      toast.success("houses details saved");

      const candidates = await this.findCandidatesAsync(this.state.data);

      this.setState({ candidates, prevState: this.state.data.houseState });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  onFileChange = (e) => {
    this.setState({ imgCollection: e.target.files });
  };

  handleDelete = async (id) => {
    await http.delete("houses/deleteimage/" + id);

    const { data: images } = await http.get(
      "houses/getimages/" + this.props.match.params.id
    );
    this.setState({
      images: images,
    });
  };

  handleSetFeturedImage = async (id) => {
    const { data: images } = await http.post(
      "houses/setfeatured/" + this.props.match.params.id + "/" + id
    );

    this.setState({
      images: images,
    });
  };

  handleDescriptionChange = (value) => {
    const { data } = this.state;
    data.description = value;

    this.setState({ data });
  };

  sendTestEmail = async () => {
    await http.post(
      "houses/testemail/" + this.state.data.id + "/" + getCurrentUser().nameid
    );

    toast.success("email sent");
  };

  permanentlyRemove = async () => {
    const editHouseId = this.props.match.params.id;
    await http.delete("houses/" + editHouseId);

    toast.success("house removed");

  }
  
  render() {
    return (
      <Container>
        <Form
          onSubmit={this.handleSubmit}
          className="mt-4"
          encType="multipart/form-data"
        >
          <Row>
            <Col className="mb-4">
              <Link  to={`/users/${this.state.data.userId}`}>
                Go to homeowner
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>{this.renderInput("title", "Title")}</Col>
          </Row>
          <Row>
            <Col>{this.renderInput("address", "Address")}</Col>
            <Col>{this.renderInput("postcode", "Postcode")}</Col>

            <Col>{this.renderSelect("cityId", "City", this.state.cities)}</Col>
            <Col>{this.renderInput("bedrooms", "Bedrooms")}</Col>
          </Row>
          <Row>
            <Col>{this.renderInput("price", "Price")}</Col>
            <Col>{this.renderInput("userId", "HomeOwnerUserId")}</Col>
            <Col>{this.renderInput("size", "Size")}</Col>
            <Col>{this.renderInput("furnishing", "Furnishing")}</Col>

            <Col>
              {this.renderSelect("houseState", "State", this.state.houseStates)}
            </Col>
          </Row>
          <Row>
            {/* <Col>
              {this.renderInput("description", "Description", "textarea")}
            </Col> */}
            <Col>
              <BaseEditor
                value={this.state.data.description}
                onChange={this.handleDescriptionChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <div className="mb-3">
                <FileUpload onChange={this.onFileChange} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <div className="mb-3">
                {this.state.images && (
                  <HouseImagesList
                    images={this.state.images}
                    onDelete={this.handleDelete}
                    onSelect={this.handleSetFeturedImage}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="my-3">
              {this.renderButton("Save")}
              {
                <Button className="ml-2" onClick={this.sendTestEmail}>
                  Send test email
                </Button>
              }
              {
                <Button className="ml-2 btn-danger" onClick={this.permanentlyRemove}>
                    Permanently remove
                </Button>
              }
            </Col>
          </Row>
        </Form>
        <Row>{<CandidatesTable candidates={this.state.candidates} />}</Row>
      </Container>
    );
  }
}

export default HouseForm;
