import React, {useEffect, useState} from "react";
import {Container, Row, Col, Button} from "reactstrap";
import http from "../../services/httpService";
import {Link} from "react-router-dom";
import DuplicatesTable from "./duplicatesTable";

export default function DuplicateDetection(props) {
    const[duplicates, setDuplicates] = useState([]);
    useEffect(() => {
        (async () => {
            const parsedHouseId = props.match.params.id;
            const {data: duplicates} = await http.get(`diagnostics/find-duplicates/${parsedHouseId}`);
            setDuplicates(duplicates);
        })();
    }, [])
    return (<Container>
        <h1>Duplicate detection</h1>
        <Row className="my-4">
            <Col>
                <Link to={`/parsedHouses/${props.match.params.id}`}> ParsedHouse </Link>
            </Col>
        </Row>
        <Row>
            <Col>
                <DuplicatesTable data={duplicates}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <Button onClick={props.history.goBack}>Back</Button>
            </Col>
        </Row>
    </Container>)
}
