import React, { Component } from "react";
import { Link } from "react-router-dom";
import http from "../../services/httpService";
import ReactTable from "react-table";
import LinkButton from "../common/linkButton";
class HousesTable extends Component {
  state = {
    data: [],
    pages: null,
    loading: true,
    houseStates: [],
  };

  delayTimer;

  async componentDidMount() {
    const { data: houseStates } = await http.get("houses/housestates");
    this.setState({ houseStates });
  }

  fetchData = async (state, instance) => {
    var self = this;
    var delayInMilliseconds = 1500; //1 second
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(async function() {
      await self.doFetchData(self, state);
    }, delayInMilliseconds);
  };

  doFetchData = async (self, state) => {
    self.setState({ loading: true });

    //your code to be executed after 1 second
    // console.log(state.pageSize, state.page, state.sorted, state.filtered);
    const { pageSize, page, sorted, filtered } = state;
    const url = self.props.getUrl();
    const { data } = await http.post(url, {
      pageSize,
      page,
      sorted,
      filtered,
    });
    self.setState({ data: data.rows, pages: data.pages, loading: false, });
  };

  columns = [
    {
      id: "edit", Header: "#", accessor: (d) => "Edit", filterable: false, sortable: false, width: 40,
      Cell: (props) => (
        <Link to={`/houses/${this.state.data[props.index].id}`}> {props.value} </Link>
      ),
    },
    {
      id: "address", Header: "Address", accessor: (d) => d.address, width: 230,
      Cell: (props) => (
        <a target="_blank" rel="noreferrer" href={this.state.data[props.index].url} > {props.value} </a>
      ),
    },
    { id: "postcode", Header: "Postcode", accessor: (d) => d.postcode, width: 80, },
    { id: "cityName", Header: "City", accessor: (d) => d.cityName, width: 130, },
    { id: "price", Header: "Price", accessor: (d) => d.price, sortable: false, width: 80, },
    { id: "size", Header: "Size", accessor: (d) => d.size, sortable: false, width: 40, },
    { id: "bedrooms", Header: "Bedrooms", accessor: (d) => d.bedrooms, sortable: false, width: 90, },
    { id: "state", Header: "State", accessor: (d) =>
        this.state.houseStates.find((h) => h.value === d.houseState).name,
      sortable: false,
      Filter: ({ filter, onChange }) => (
          <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"} >
            <option value="all">All</option>
            <option value="Active">Active</option>
            <option value="Deleted">Deleted</option>
            <option value="New">New</option>
            <option value="OnHold">OnHold</option>
            <option value="Rented">Rented</option>
          </select>
      )
    },
    { id: "furnishing", Header: "Furnishing", accessor: (d) => d.furnishing, sortable: false, },
    { id: "createDate", Header: "Created", accessor: (d) => d.createDate, filterable: true, width: 190, },
    { id: "modifiedDate", Header: "Modified", accessor: (d) => d.modifiedDate, filterable: true, width: 190,
    },
  ];

  render() {
    const { data, loading, pages } = this.state;
    const columns = this.columns;
    return (
      <React.Fragment>
        <LinkButton text="create" url={`/houses/new`} />
        <ReactTable
          manual className="my-4" data={data} pages={pages} loading={loading} columns={columns}
          filterable onFetchData={this.fetchData}
        />
      </React.Fragment>
    );
  }
}
export default HousesTable;
